import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-paid-box',
  templateUrl: './paid-box.component.html',
  styleUrls: ['./paid-box.component.scss'],
})
export class PaidBoxComponent implements OnInit {

  data: any;

  constructor(private modal: ModalController) { }

  dismiss() {
    this.modal.dismiss();
  }

  save() {
    this.modal.dismiss(this.data);
  }

  ngOnInit() {}

}
