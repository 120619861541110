import { Injectable } from '@angular/core';
import { ToastController, LoadingController, AlertController } from '@ionic/angular';
import { AlertOptions } from '@ionic/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  loading: any;
  globalPath = environment.serverPath;
  isLoading = false;
  constructor(
    private toast: ToastController,
    private loadingCtrl: LoadingController,
    private alert: AlertController) { }

  async showToast(message: string, color: string = 'success', duration: number = 2000) {
    const toastOptions = {
      message,
      duration,
      color,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel'
        }
      ]
    };
    const toast = await this.toast.create(toastOptions);
    toast.present();
  }

  async showLoading(message = 'Cargando...', duration = 5000) {
    if (!this.isLoading) {
      this.isLoading = true;
      console.log('show loading');
      this.loading = await this.loadingCtrl.create({
        message,
        duration
      });
      return await this.loading.present();
    }
  }

  async hideLoading() {
    this.isLoading = false;
    return await this.loading.dismiss();
  }

  async showAlertOnlyButtonOk(header: string, subHeader: string, message: any) {
    const opts: AlertOptions = {
      header,
      message,
      buttons: ['Ok']
    };
    if (subHeader) {
      opts.subHeader = subHeader;
    }
    const alert = await this.alert.create(opts);

    await alert.present();
  }


  parseDecimals(num: number) {
    if (num) {
      return (parseFloat(num.toString())).toFixed(2);
    } else {
      return num;
    }
  }


  removeDuplicatesArray(arr: any[]) {
    const newArr = arr.filter((elem, index) => {
      const e = JSON.stringify(elem);
      return index === arr.findIndex(obj => {
        return JSON.stringify(obj) === e;
      });
    });
    return newArr;
  }

  groupByUnitsAndProducts(xs, key) {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x.unit);
      return rv;
    }, {});
  }

  groupByUnitsAndProductsLong2(xs, key) {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push({ units: x.unit ? x.unit : 0 });
      return rv;
    }, {});
  }

}