import { Component, OnDestroy } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, NavigationEnd } from '@angular/router';
import { SubscribeDataService } from './services/subscribe-data/subscribe-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnDestroy {
  selectedPage = 'login';
  menuIsOpen = false;
  private menuSub: Subscription;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private subsDataService: SubscribeDataService
  ) {
    this.initializeApp();
    this.menuSub = this.subsDataService.observeMenuStatus().subscribe((state: boolean) => {
      this.menuIsOpen = state;
    });
  }

  ngOnDestroy() {
    this.menuSub.unsubscribe();
  }

  async initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.router.events.subscribe(async event => {
        if (event instanceof NavigationEnd) {
          const page = event.url.split('/')[1];
          if (page !== '') {
            if(page.includes("?")){
              var part = page.split('?')[0];
              this.selectedPage = part;
            } else {
              this.selectedPage = page;
            }           
            console.log(page);
          }
        }
      });
    });
  }

  async setMenuStatus() {
    await this.subsDataService.checkMenuStatus();
  }

}
