import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MenuController } from '@ionic/angular';


@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
  sections = ['Inicio', 'Productos', 'Nosotros', 'Contacto'];
  @Output()
  menuAction: EventEmitter<any> = new EventEmitter();

  constructor
    (
      private menu: MenuController
    ) { }

  ngOnInit() { }


  async action(data: string) {
    await this.menu.close();
    if (data === 'Inicio') {
      this.menuAction.emit('top');
    } else if (data === 'Productos') {
      this.menuAction.emit('products');
    } else if (data === 'Nosotros') {
      this.menuAction.emit('footprint');
    } else if (data === 'Contacto') {
      this.menuAction.emit('contact');
    }
  }

}
