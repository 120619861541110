import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})

export class MenuGuard implements CanActivate {
  userRole: any;

  constructor(private storage: StorageService, private router: Router) { }

  /*canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }*/

  async canActivate() {
    const result = await this.storage.getUser();
    this.userRole = result.role;
    /*if (this.userRole === 'manager') {
      this.router.navigate(['/clientes']);
      return false;
    }*/
    if (this.userRole === 'repartidor') {
      this.router.navigate(['/clientes']);
    }
    return true;
  }
}
