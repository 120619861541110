import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input()
  columns: any[];

  @Input()
  lines: any[];

  @Input()
  withInput = false;

  @Input()
  maxPage: number;

  @Input()
  currentPage: any;

  @Input()
  pagination = false;

  @Output() doActionParent = new EventEmitter();
  @Output() paginacionParent = new EventEmitter();

  protected sortColumn;
  protected isAscending;

  constructor() {
    this.sortColumn = '';
    this.isAscending = true;
  }

  ngOnInit() {
    if (this.maxPage) {
      console.log(this.currentPage);
    }
  }

  doAction(action, line) {
    const sendData = { action, line };
    this.doActionParent.emit(sendData);
  }

  trackById(i, line) {
    return line._id;
  }

  checkDisabled(line, validate, validateType = 'string') {
    if (validateType === 'boolean') {
      return line[validate];
    } else {
      if (line[validate].length !== 0) {
        return false;
      } else {
        return true;
      }
    }
  }

  prev() {
    console.log(this.currentPage);
    console.log(this.maxPage);
    // this.doActionParent.emit(-1);
    this.paginacionParent.emit(-1);
  }
  next() {
    console.log(this.currentPage);
    console.log(this.maxPage);
    // this.doActionParent.emit(1);
    this.paginacionParent.emit(1);
  }

  toggleSort(column: string) {
    if (column != "action") {
      if (this.sortColumn === column) {
        this.isAscending = !this.isAscending;
      } else {
        this.sortColumn = column;
        this.isAscending = true;
      }
      this.lines.sort((a, b) => {
        const aValue = a[column].toString();;
        const bValue = b[column].toString();

        const numericA = typeof aValue === 'number' ? aValue : parseFloat(aValue);
        const numericB = typeof bValue === 'number' ? bValue : parseFloat(bValue);

        if (!isNaN(numericA) && !isNaN(numericB)) {
          if (this.isAscending) {
            return numericA - numericB;
          } else {
            return numericB - numericA;
          }
        } else {
          if (this.isAscending) {
            return String(aValue).localeCompare(String(bValue));
          } else {
            return String(bValue).localeCompare(String(aValue));
          }
        }
      });
    }
  }
}
