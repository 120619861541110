import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { User } from 'src/app/interfaces/user';
import { Car } from 'src/app/interfaces/car';
import { Carga } from 'src/app/interfaces/carga';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RestService } from 'src/app/services/rest/rest.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { Stock } from 'src/app/interfaces/stock';

@Component({
  selector: 'app-add-carga',
  templateUrl: './add-carga.component.html',
  styleUrls: ['./add-carga.component.scss'],
})
export class AddCargaComponent implements OnInit {
  data = {
    car: '',
    responsable: '',
    lote: '',
    '5eb9c9e3d0ca13a4332680dc': 0,
    '5eb9c9e3d0ca13a4332680db': 0,
    '5eb9c9e3d0ca13a4332680d9': 0,
    '5eb9c9e3d0ca13a4332680da': 0,
    '5ef5e43bdd4795f0c32a152b': 0,
    '5ea75b95ece96f572f598255': 0,
    '5ef5e43bdd4795f0c32a152c': 0,
    '5ef5e43bdd4795f0c32a152d': 0,
    '5ef5e43bdd4795f0c32a152e': 0,
    '5ef5e43bdd4795f0c32a1531': 0,
    '6509a1e01bb0978fd8b5b15f': 0,
    '6509a4241bb0978fd8b5b161': 0,
    '650dbb3eb78327095c20a226': 0,
    '650aae4834900b974d3fba94': 0,
    '650aae7934900b974d3fba95': 0,
    '650aae9a34900b974d3fba96': 0,
    '650aaebe34900b974d3fba97': 0,
  };

  disabledCar = false;

  repartidores: User[] = [];
  cars: Car[] = [];
  cargasForm: FormGroup;
  cargas: Carga[] = [];
  verSeleccion = '';
  myUserCar = 0;

  cubito5eb9c9e3d0ca13a4332680dc: number;
  cuadrado5eb9c9e3d0ca13a4332680db: number;
  pilee5eb9c9e3d0ca13a4332680d9: number;
  escama5eb9c9e3d0ca13a4332680da: number;
  triturado5ef5e43bdd4795f0c32a152b: number;
  cubito5ea75b95ece96f572f598255: number;
  cuadrado5ef5e43bdd4795f0c32a152c: number;
  pilee5ef5e43bdd4795f0c32a152d: number;
  escama5ef5e43bdd4795f0c32a152e: number;
  triturado5ef5e43bdd4795f0c32a1531: number;
  cubo6509a1e01bb0978fd8b5b15f: number;
  lingote6509a4241bb0978fd8b5b161: number;
  esferas650dbb3eb78327095c20a226: number;
  cubo650aae4834900b974d3fba94: number;
  cubo650aae7934900b974d3fba95: number;
  lingote650aae9a34900b974d3fba96: number;
  esferas650aaebe34900b974d3fba97: number;

  lines: any[] = [];

  constructor(private modal: ModalController, private utils: UtilsService, private rest: RestService) {
    this.cargasForm = new FormGroup({
      '5eb9c9e3d0ca13a4332680dc': new FormControl(0, [Validators.min(0), Validators.required]),
      '5eb9c9e3d0ca13a4332680db': new FormControl(0, [Validators.min(0), Validators.required]),
      '5eb9c9e3d0ca13a4332680d9': new FormControl(0, [Validators.min(0), Validators.required]),
      '5eb9c9e3d0ca13a4332680da': new FormControl(0, [Validators.min(0), Validators.required]),
      '5ef5e43bdd4795f0c32a152b': new FormControl(0, [Validators.min(0), Validators.required]),
      '5ea75b95ece96f572f598255': new FormControl(0, [Validators.min(0), Validators.required]),
      '5ef5e43bdd4795f0c32a152c': new FormControl(0, [Validators.min(0), Validators.required]),
      '5ef5e43bdd4795f0c32a152d': new FormControl(0, [Validators.min(0), Validators.required]),
      '5ef5e43bdd4795f0c32a152e': new FormControl(0, [Validators.min(0), Validators.required]),
      '5ef5e43bdd4795f0c32a1531': new FormControl(0, [Validators.min(0), Validators.required]),
      '6509a1e01bb0978fd8b5b15f': new FormControl(0, [Validators.min(0), Validators.required]),
      '6509a4241bb0978fd8b5b161': new FormControl(0, [Validators.min(0), Validators.required]),
      '650dbb3eb78327095c20a226': new FormControl(0, [Validators.min(0), Validators.required]),
      '650aae4834900b974d3fba94': new FormControl(0, [Validators.min(0), Validators.required]),
      '650aae7934900b974d3fba95': new FormControl(0, [Validators.min(0), Validators.required]),
      '650aae9a34900b974d3fba96': new FormControl(0, [Validators.min(0), Validators.required]),
      '650aaebe34900b974d3fba97': new FormControl(0, [Validators.min(0), Validators.required]),

      lote: new FormControl('', [Validators.required]),
      repartidor: new FormControl('', [Validators.required]),
      vehiculo: new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {
    console.log(this.cars);
    for (const repartidor of this.repartidores) {
      for (const carga of this.cargas) {
        if (carga.repartidor === repartidor.firstName) {
          repartidor.cargas.push(carga);
        }
      }
    }
    this.getAllStock();
  }

  addProducts() {
    console.log(this.data);
    this.validateProducts();
  }

  async validateProducts() {
    const cubito5eb9c9e3d0ca13a4332680dc = this.data['5eb9c9e3d0ca13a4332680dc'];
    const cuadrado5eb9c9e3d0ca13a4332680db = this.data['5eb9c9e3d0ca13a4332680db'];
    const pilee5eb9c9e3d0ca13a4332680d9 = this.data['5eb9c9e3d0ca13a4332680d9'];
    const escama5eb9c9e3d0ca13a4332680da = this.data['5eb9c9e3d0ca13a4332680da'];
    const triturado5ef5e43bdd4795f0c32a152b = this.data['5ef5e43bdd4795f0c32a152b'];
    const cubito5ea75b95ece96f572f598255 = this.data['5ea75b95ece96f572f598255'];
    const cuadrado5ef5e43bdd4795f0c32a152c = this.data['5ef5e43bdd4795f0c32a152c'];
    const pilee5ef5e43bdd4795f0c32a152d = this.data['5ef5e43bdd4795f0c32a152d'];
    const escama5ef5e43bdd4795f0c32a152e = this.data['5ef5e43bdd4795f0c32a152e'];
    const triturado5ef5e43bdd4795f0c32a1531 = this.data['5ef5e43bdd4795f0c32a1531'];
    const cubo6509a1e01bb0978fd8b5b15f = this.data['6509a1e01bb0978fd8b5b15f'];
    const lingote6509a4241bb0978fd8b5b161 = this.data['6509a4241bb0978fd8b5b161'];
    const esferas650dbb3eb78327095c20a226 = this.data['650dbb3eb78327095c20a226'];
    const cubo650aae4834900b974d3fba94 = this.data['650aae4834900b974d3fba94'];
    const cubo650aae7934900b974d3fba95 = this.data['650aae7934900b974d3fba95'];
    const lingote650aae9a34900b974d3fba96 = this.data['650aae9a34900b974d3fba96'];
    const esferas650aaebe34900b974d3fba97 = this.data['650aaebe34900b974d3fba97'];
    if (
      (cubito5eb9c9e3d0ca13a4332680dc > this.cubito5eb9c9e3d0ca13a4332680dc) ||
      (cuadrado5eb9c9e3d0ca13a4332680db > this.cuadrado5eb9c9e3d0ca13a4332680db) ||
      (pilee5eb9c9e3d0ca13a4332680d9 > this.pilee5eb9c9e3d0ca13a4332680d9) ||
      (escama5eb9c9e3d0ca13a4332680da > this.escama5eb9c9e3d0ca13a4332680da) ||
      (triturado5ef5e43bdd4795f0c32a152b > this.triturado5ef5e43bdd4795f0c32a152b) ||
      (cubito5ea75b95ece96f572f598255 > this.cubito5ea75b95ece96f572f598255) ||
      (cuadrado5ef5e43bdd4795f0c32a152c > this.cuadrado5ef5e43bdd4795f0c32a152c) ||
      (pilee5ef5e43bdd4795f0c32a152d > this.pilee5ef5e43bdd4795f0c32a152d) ||
      (escama5ef5e43bdd4795f0c32a152e > this.escama5ef5e43bdd4795f0c32a152e) ||
      (triturado5ef5e43bdd4795f0c32a1531 > this.triturado5ef5e43bdd4795f0c32a1531) ||
      (cubo6509a1e01bb0978fd8b5b15f > this.cubo6509a1e01bb0978fd8b5b15f) ||
      (lingote6509a4241bb0978fd8b5b161 > this.lingote6509a4241bb0978fd8b5b161) ||
      (esferas650dbb3eb78327095c20a226 > this.esferas650dbb3eb78327095c20a226) ||
      (cubo650aae4834900b974d3fba94 > this.cubo650aae4834900b974d3fba94) ||
      (cubo650aae7934900b974d3fba95 > this.cubo650aae7934900b974d3fba95) ||
      (lingote650aae9a34900b974d3fba96 > this.lingote650aae9a34900b974d3fba96) ||
      (esferas650aaebe34900b974d3fba97 > this.esferas650aaebe34900b974d3fba97) 
      ) {
      await this.utils.hideLoading();
      await this.utils.showToast('El número de unidades es mayor al que hay en stock', 'danger', 3000);
    } else {
      this.modal.dismiss(this.data);
    }
  }

  dismiss() {
    this.modal.dismiss();
  }

  checkButtonStatus() {
    if ((this.data.responsable === '') ||
      (this.data.lote === '') ||
      (this.data.lote === undefined) ||
      (this.data.car === '') ||
      (this.data.car === undefined)) {
      return true;
    }
    if (
      this.data['5eb9c9e3d0ca13a4332680dc'] === null &&
      this.data['5eb9c9e3d0ca13a4332680db'] === null &&
      this.data['5eb9c9e3d0ca13a4332680d9'] === null &&
      this.data['5eb9c9e3d0ca13a4332680da'] === null &&
      this.data['5ef5e43bdd4795f0c32a152b'] === null &&
      this.data['5ea75b95ece96f572f598255'] === null &&
      this.data['5ef5e43bdd4795f0c32a152c'] === null &&
      this.data['5ef5e43bdd4795f0c32a152d'] === null &&
      this.data['5ef5e43bdd4795f0c32a152e'] === null &&
      this.data['5ef5e43bdd4795f0c32a1531'] === null &&
      this.data['6509a1e01bb0978fd8b5b15f'] === null &&
      this.data['6509a4241bb0978fd8b5b161'] === null &&
      this.data['650dbb3eb78327095c20a226'] === null &&
      this.data['650aae4834900b974d3fba94'] === null &&
      this.data['650aae7934900b974d3fba95'] === null &&
      this.data['650aae9a34900b974d3fba96'] === null &&
      this.data['650aaebe34900b974d3fba97'] === null 
      ) {
      return true;
    }
    if (
      this.data['5eb9c9e3d0ca13a4332680dc'] === 0 &&
      this.data['5eb9c9e3d0ca13a4332680db'] === 0 &&
      this.data['5eb9c9e3d0ca13a4332680d9'] === 0 &&
      this.data['5eb9c9e3d0ca13a4332680da'] === 0 &&
      this.data['5ef5e43bdd4795f0c32a152b'] === 0 &&
      this.data['5ea75b95ece96f572f598255'] === 0 &&
      this.data['5ef5e43bdd4795f0c32a152c'] === 0 &&
      this.data['5ef5e43bdd4795f0c32a152d'] === 0 &&
      this.data['5ef5e43bdd4795f0c32a152e'] === 0 &&
      this.data['5ef5e43bdd4795f0c32a1531'] === 0 &&
      this.data['6509a1e01bb0978fd8b5b15f'] === 0 &&
      this.data['6509a4241bb0978fd8b5b161'] === 0 &&
      this.data['650dbb3eb78327095c20a226'] === 0 &&
      this.data['650aae4834900b974d3fba94'] === 0 &&
      this.data['650aae7934900b974d3fba95'] === 0 &&
      this.data['650aae9a34900b974d3fba96'] === 0 &&
      this.data['650aaebe34900b974d3fba97'] === 0 
      ) {
      return true;
    }
  }

  assignVehicle() {
    const userCar = {};
    this.cargas.forEach((carga, index) => {
      if (!carga.closed) {
        userCar[carga.user] = carga.car;
      }
    });

    if (userCar[this.data.responsable]) {
      this.disabledCar = true;
      this.data.car = userCar[this.data.responsable];
    } else {
      this.disabledCar = false;
      this.data.car = '';
    }
  }

  checkAvailableCars(id) {
    let cars: any = this.cargas.filter(elem => !elem.closed);
    if (cars && cars.length > 0) {
      cars = cars.map(elem => elem.car);
      if (cars.includes(id)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  async getAllStock() {
    try {
      await this.utils.showLoading();

      const respAll: any = await this.rest.getAllStockList();
      console.log(respAll.body);
      const resp: any = await this.rest.getStocks();
      if (resp !== false) {
        console.log(resp.body.records);
        const currentResults: Stock = resp.body.records;
        if (currentResults) {
          const line: Stock = {
            _id: currentResults._id,
            transaction: currentResults.transaction,
            createdAt: currentResults.createdAt
          };
          console.log(line);
          line.transaction.forEach(elem => {
            this.lines.push({
              productName: (elem.product as any).name,
              palete: Math.floor(elem.total / 99),
              total: 99,
              sueltas: elem.total - Math.floor(elem.total / 99) * 99,
              total_ud: elem.total
            });
          });
        }
        console.log(this.lines);
        await this.utils.hideLoading();
      } else {
        await this.utils.hideLoading();
        await this.utils.showToast('Error al obtener el stock', 'danger');
      }
    } catch (error) {
      console.log(error);
      await this.utils.hideLoading();
      await this.utils.showToast('Error al obtener el stock', 'danger');
    }

    for (const stock of this.lines) {
      if (stock.productName === 'CUBITO VOGT') {
        this.cubito5eb9c9e3d0ca13a4332680dc = stock.total_ud;
      }
      if (stock.productName === 'CUBITO SCOTSMAN') {
        this.cuadrado5eb9c9e3d0ca13a4332680db = stock.total_ud;
      }
      if (stock.productName === 'HIELO PILEE') {
        this.pilee5eb9c9e3d0ca13a4332680d9 = stock.total_ud;
      }
      if (stock.productName === 'ESCAMA RED') {
        this.escama5eb9c9e3d0ca13a4332680da = stock.total_ud;
      }
      if (stock.productName === 'ESCAMA CUAD') {
        this.triturado5ef5e43bdd4795f0c32a152b = stock.total_ud;
      }
      if (stock.productName === 'HIELO CUADRADO') {
        this.cubito5ea75b95ece96f572f598255 = stock.total_ud;
      }
      if (stock.productName === 'BARRA') {
        this.cuadrado5ef5e43bdd4795f0c32a152c = stock.total_ud;
      }
      if (stock.productName === 'BLOQUES') {
        this.pilee5ef5e43bdd4795f0c32a152d = stock.total_ud;
      }
      if (stock.productName === 'HIELO SECO') {
        this.escama5ef5e43bdd4795f0c32a152e = stock.total_ud;
      }
      if (stock.productName === 'HIELO ESPECIAL') {
        this.triturado5ef5e43bdd4795f0c32a1531 = stock.total_ud;
      }
      if (stock.productName === 'CUBO 5X5') {
        this.cubo6509a1e01bb0978fd8b5b15f = stock.total_ud;
      }
      if (stock.productName === 'LINGOTE 4X12') {
        this.lingote6509a4241bb0978fd8b5b161 = stock.total_ud;
      }
      if (stock.productName === 'ESFERAS 6CM') {
        this.esferas650dbb3eb78327095c20a226 = stock.total_ud;
      }
      if (stock.productName === 'CUBO 5,5 X 5,5') {
        this.cubo650aae4834900b974d3fba94 = stock.total_ud;
      }
      if (stock.productName === 'CUBO 5x7') {
        this.cubo650aae7934900b974d3fba95 = stock.total_ud;
      }
      if (stock.productName === 'LINGOTE 3x15') {
        this.lingote650aae9a34900b974d3fba96 = stock.total_ud;
      }
      if (stock.productName === 'ESFERAS 3CM') {
        this.esferas650aaebe34900b974d3fba97 = stock.total_ud;
      }
    }
  }

  close() {
    this.modal.dismiss();
  }
}