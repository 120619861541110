
import { Component, OnInit } from '@angular/core';
// import { User } from 'src/app/interfaces/user';
import { Product } from 'src/app/interfaces/product';
import { ModalController } from '@ionic/angular';
import { ProductById } from 'src/app/data/products';

@Component({
  selector: 'app-accept-carga',
  templateUrl: './accept-carga.component.html',
  styleUrls: ['./accept-carga.component.scss'],
})
export class AcceptCargaComponent implements OnInit {

  carga: any;
  products: Product[] = [];
  productsCarga = [];

  constructor(private modal: ModalController) { }

  parseNameProduct(id: string) {
    return ProductById(id);
  }

  async accept() {
    await this.modal.dismiss({closed: true});
  }

  async ngOnInit() {
    console.log(this.carga);
  }
}

