import { Component, OnInit, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Input()
  buttonActive = 'clientes';
  buttons: any;
  userRole: any;
  
  isQrControlRoute: boolean = false;  

  constructor(
    private nav: NavController,
    private storage: StorageService,
    private router: Router) {
      this.isQrControlRoute = this.isCurrentRouteQrControl();
     }

  ngOnInit() {
    this.menuToUserRole();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isQrControlRoute = this.isCurrentRouteQrControl();
      }
    });
  }

  async menuToUserRole() {
    
    const result = await this.storage.getUser();
    this.userRole = result.role;

    if (this.userRole !== 'repartidor' && this.userRole != 'cliente') {
      this.buttons = [
        {
          icon: 'clientes',
          name: 'Clientes',
          link: 'clientes'
        },
        {
          icon: 'tarifa',
          name: 'Tarifas',
          link: 'tarifas'
        },
        {
          icon: 'promotion',
          name: 'Promociones',
          link: 'promotions'
        },
        {
          icon: 'stock',
          name: 'Control stock',
          link: 'stock'
        },
        {
          icon: 'cargas',
          name: 'Cargas',
          link: 'cargas'
        },
        {
          icon: 'albaran',
          name: 'Albaranes',
          link: 'albaranes'
        },
        {
          icon: 'zonas',
          name: 'Zonas',
          link: 'zonas'
        },
        {
          icon: 'car',
          name: 'Vehiculos',
          link: 'cars'
        },
        {
          icon: 'close_session',
          name: 'Cierre sesión',
          link: 'close-session'
        },
        {
          icon: 'factura',
          name: 'Facturas',
          link: 'facturas'
        },
        {
          icon: 'work',
          name: 'Control',
          link: 'control'
        },
        {
          icon: 'settings',
          name: 'Ajustes',
          link: 'ajustes'
        },
        {
          icon: 'car',
          name: 'Garaje',
          link: 'garaje'
        },
        {
          icon: 'mail',
          name: 'Mailgun',
          link: 'failed-emails'
        }
      ];

      if (this.userRole === 'admin') {
        this.buttons.splice(5, 0,
          {
            icon: 'users',
            name: 'Usuarios',
            link: 'users'
          },
          {
            icon: 'statistic',
            name: 'Estadísticas',
            link: 'estadisticas'
          }
        )
      }

    }
  }

  logout() {
    this.nav.navigateRoot('/login');
  }

  private isCurrentRouteQrControl(): boolean {
    const urlSegments = this.router.url.split('/');
    return urlSegments.length >= 2 && urlSegments[1] === 'qrcontrol';
  }

}
