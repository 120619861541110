import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-add-stock',
  templateUrl: './add-stock.component.html',
  styleUrls: ['./add-stock.component.scss'],
})
export class AddStockComponent implements OnInit {
  data = {
    creator: null,
    comment: '',
    '5eb9c9e3d0ca13a4332680dc': null,
    '5eb9c9e3d0ca13a4332680db': null,
    '5eb9c9e3d0ca13a4332680d9': null,
    '5eb9c9e3d0ca13a4332680da': null,
    '5ef5e43bdd4795f0c32a152b': null,
    '5ea75b95ece96f572f598255': null,
    '5ef5e43bdd4795f0c32a152c': null,
    '5ef5e43bdd4795f0c32a152d': null,
    '5ef5e43bdd4795f0c32a152e': null,
    '5ef5e43bdd4795f0c32a1531': null,
    '6509a1e01bb0978fd8b5b15f': null,
    '6509a4241bb0978fd8b5b161': null,
    '650dbb3eb78327095c20a226': null,
    '650aae4834900b974d3fba94': null,
    '650aae7934900b974d3fba95': null,
    '650aae9a34900b974d3fba96': null,
    '650aaebe34900b974d3fba97': null,
  };

  users = [];
  isAdd: boolean;
  user: any;

  constructor(private modal: ModalController,
    private utils: UtilsService,
    private rest: RestService,
    private storage: StorageService) { }

  ngOnInit() {
    this.getUser();
  }
  async getUser() {
    this.user = await this.storage.getUser();
    if (this.user) {
      this.data.creator = this.user._id
    }
  }

  async ionViewWillEnter() {
    await this.getUsers();
  }

  async getUsers() {
    try {
      const resp: any = await this.rest.getUsers(1, 50);
      if (resp) {
        this.users = resp.body.records;
        console.log(this.users);
        this.users = this.users.filter(user => user.role !== 'repartidor');
      }
    } catch (error) {
      console.log(error);
      await this.utils.showToast('Error cargando managers', 'danger', 3000);
    }
  }

  addProducts() {
    this.modal.dismiss(this.data);
  }

  dismiss() {
    this.modal.dismiss();
  }

  checkButtonStatus() {
    if (this.data.creator === '') {
      return true;
    }
    if (
      this.data['5eb9c9e3d0ca13a4332680dc'] === null &&
      this.data['5eb9c9e3d0ca13a4332680db'] === null &&
      this.data['5eb9c9e3d0ca13a4332680d9'] === null &&
      this.data['5eb9c9e3d0ca13a4332680da'] === null &&
      this.data['5ef5e43bdd4795f0c32a152b'] === null &&
      this.data['5ea75b95ece96f572f598255'] === null &&
      this.data['5ef5e43bdd4795f0c32a152c'] === null &&
      this.data['5ef5e43bdd4795f0c32a152d'] === null &&
      this.data['5ef5e43bdd4795f0c32a152e'] === null &&
      this.data['5ef5e43bdd4795f0c32a1531'] === null &&
      this.data['6509a1e01bb0978fd8b5b15f'] === null &&
      this.data['6509a4241bb0978fd8b5b161'] === null &&
      this.data['650dbb3eb78327095c20a226'] === null &&
      this.data['650aae4834900b974d3fba94'] === null &&
      this.data['650aae7934900b974d3fba95'] === null &&
      this.data['650aae9a34900b974d3fba96'] === null &&
      this.data['650aaebe34900b974d3fba97'] === null 
    ) {
      return true;
    }
    return false;
  }

  close() {
    this.modal.dismiss();
  }
}
