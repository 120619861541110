import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { User } from 'src/app/interfaces/user';
import { Client } from 'src/app/interfaces/client';
import { UtilsService } from '../utils/utils.service';
import { Albaran } from 'src/app/interfaces/albaran';
import { Car } from 'src/app/interfaces/car';
import { Factura } from 'src/app/interfaces/factura';
import { Geotracking } from 'src/app/interfaces/geotracking';
import { Product } from 'src/app/interfaces/product';
import { Promotion } from 'src/app/interfaces/promocion';
import { Session } from 'src/app/interfaces/session';
import { Stock } from 'src/app/interfaces/stock';
import { Tarifa } from 'src/app/interfaces/tarifa';
import { Zona } from 'src/app/interfaces/zona';
import { Carga } from 'src/app/interfaces/carga';
import { Gestion } from 'src/app/interfaces/gestion';
import { Config } from 'src/app/interfaces/config';
import { Setting } from 'src/app/interfaces/setting';
import { ClientGaraje } from 'src/app/interfaces/clientGaraje';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RestService {

  globalPath = environment.serverPath;
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient,
    private storage: StorageService,
    private utils: UtilsService) { }

  login(username: string, password: string) {
    const body = {
      username,
      password
    };
    console.log(body);
    return new Promise(resolve => {
      this.http.post(this.globalPath + 'auth/login', body, { headers: this.headers }).subscribe(
        (data: any) => {
          console.log(data);
          console.log(data.accessToken);
          if (data.accessToken) {
            this.storage.setItem('currentUser', data);
            this.storage.setItem('accessToken', data.accessToken);
            this.headers.append('Authorization', `Bearer ${data.accessToken}`);
            console.log(this.headers);
          }
          resolve(data);
        },
        error => {
          console.log(error);
          resolve(false);
        }
      );
    });
  }

  async logout() {
    const currentUser: User = await this.storage.getItem('currentUser');
    const token: string = currentUser.accessToken;
    console.log(token);

    return new Promise(resolve => {
      this.http.post(this.globalPath + 'auth/logout', null, { headers: this.headers }).subscribe(
        (resp: any) => {
          console.log(resp);
          resolve(resp);
        },
        error => {
          console.log(error);
          resolve(false);
        }
      );
    });
  }


  /////////////////////////////////////////
  //////////////// MAILGUN ////////////////
  /////////////////////////////////////////

  private apiUrl = '';
  private apiKey = 'ZmU5Y2YwYTgtZTI3OGYyODE6M2YxN2YxZjFlZDhiMDcwYmYzYTA4MzEzMmI1YWNiNjYtZmU5Y2YwYTgtZTI3OGYyODE='; // API Key Mailgun

  getFailedEmails(domain: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Basic ${this.apiKey}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let params = new HttpParams()
      .set('event', 'failed')

      this.apiUrl = `https://api.eu.mailgun.net/v3/${domain}/events`;

    return this.http.get<any>(this.apiUrl, { headers, params });
  }

  /////////////////////////////////////////
  //////////////// CLIENTS ////////////////
  /////////////////////////////////////////
  async createClient(client: Client) {
    return await this.generalCreate('clients', client);
  }
  async getClients(page: number = 0, limit: number = 50): Promise<any> {
    console.log(this.headers);
    return await this.generalGetAll('clients', page, limit);
  }
  async getClientsNames(page: number = 0, limit: number = 10): Promise<any> {
    return await this.generalGetAllNoParams('clients/names');
  }
  async getAllClients(page: number = 1, limit: number = 9999999): Promise<any> {
    console.log(this.headers);
    return await this.generalGetAll('clients', page, limit);
  }
  async getClientsByNameDocument(page: number = 1, limit: number = 50, name: string = null, dni: string = null): Promise<any> {
    console.log(this.headers);
    const params = {
      page,
      limit,
      name,
      dni
    };
    return await this.specialGetAll('clients', params);
  }
  async getClientsByName(name: string, nonPrimary = false): Promise<any> {
    console.log(this.headers);
    return await this.generalGetByName('clients', name, nonPrimary.toString());
  }

  async getClientsByNameWithToken(name: string, nonPrimary = false): Promise<any> {
    console.log(this.headers);
    return await this.generalGetByNameWithToken('clients', name, nonPrimary.toString());
  }

  async getClientNames(name: string, nonPrimary = false): Promise<any> {
    console.log(this.headers);
    return await this.generalGetByName('clients/names', name, nonPrimary.toString());
  }
  
  async getClient(clientId: string): Promise<Client> {
    return await this.generalGetById('clients', clientId) as Client;
  }

  async getClientWithToken(clientId: string): Promise<Client> {
    return await this.generalGetByIdWithToken('clients', clientId) as Client;
  }
  async getClientStatistics(clientId: string, start: string, end: string): Promise<Client> {
    let queryParams = '';
    if (start && end) {
      queryParams += `?start=${start}&end=${end}`;
    }
    return await this.generalGetById('clients/statistics', clientId + queryParams,);
  }
  async editClient(client: Client) {
    return await this.generalEdit('clients', client._id, client);
  }
  async deleteClient(clientId: string) {
    return await this.generalDelete('clients', clientId);
  }

   /////////////////////////////////////////
  ///////////// CLIENTS GARAJE/////////////
  /////////////////////////////////////////
  async createClientGaraje(client: ClientGaraje) {
    // alert (client.ccc);
    return await this.generalCreate('clientsgaraje', client);
  }
  async getClientsGaraje(page: number = 0, limit: number = 50): Promise<any> {
    console.log(this.headers);
    return await this.generalGetAll('clientsgaraje', page, limit);
  }
  async getClientsGarajeNames(page: number = 0, limit: number = 10): Promise<any> {
    return await this.generalGetAllNoParams('clientsgaraje/names');
  }
  async getAllClientsGaraje(page: number = 1, limit: number = 9999999): Promise<any> {
    console.log(this.headers);
    return await this.generalGetAll('clientsgaraje', page, limit);
  }
  async getClientsGarajeByNameDocument(page: number = 1, limit: number = 50, name: string = null, dni: string = null): Promise<any> {
    console.log(this.headers);
    const params = {
      page,
      limit,
      name,
      dni
    };
    return await this.specialGetAll('clientsgaraje', params);
  }
  async getClientsGarajeByName(name: string, nonPrimary = false): Promise<any> {
    console.log(this.headers);
    return await this.generalGetByName('clientsgaraje', name, nonPrimary.toString());
  }

  async getClientGarajeNames(name: string, nonPrimary = false): Promise<any> {
    console.log(this.headers);
    return await this.generalGetByName('clientsgaraje/names', name, nonPrimary.toString());
  }
  
  async getClientGaraje(clientId: string): Promise<ClientGaraje> {
    return await this.generalGetById('clientsgaraje', clientId) as ClientGaraje;
  }
  // async getClientStatisticsGaraje(clientId: string, start: string, end: string): Promise<ClientGaraje> {
  //   let queryParams = '';
  //   if (start && end) {
  //     queryParams += `?start=${start}&end=${end}`;
  //   }
  //   return await this.generalGetById('clientsgaraje/statistics', clientId + queryParams);
  // }
  async editClientGaraje(client: ClientGaraje) {
    return await this.generalEdit('clientsgaraje', client._id, client);
  }
  async deleteClientGaraje(clientId: string) {
    return await this.generalDelete('clientsgaraje', clientId);
  }

  /////////////////////////////////////////
  //////////////// USERS ////////////////
  /////////////////////////////////////////
  async createUser(user: User) {
    return await this.generalCreate('users', user);
  }
  async getUsers(page: number = 1, limit: number = 50): Promise<User> {
    return await this.generalGetAll('users', page, limit) as User;
  }
  async getUsersWithCargas(page: number = 1, limit: number = 50): Promise<User> {
    return await this.generalGetAll('users/with-cargas', page, limit) as User;
  }

  async getUsersWithCargasWithToken(page: number = 1, limit: number = 50): Promise<User> {
    return await this.generalGetAllWithToken('users/with-cargas', page, limit) as User;
  }
  async getUser(usersId: string) {
    return await this.generalGetById('users', usersId);
  }
  async getUserWithToken(usersId: string) {
    return await this.generalGetByIdWithToken('users', usersId);
  }
  async editUser(user: User) {
    return await this.generalEdit('users', user._id, user);
  }
  async deleteUser(usersId: string) {
    return await this.generalDelete('users', usersId);
  }

  /////////////////////////////////////////
  //////////////// ALBARANES ////////////////
  /////////////////////////////////////////
  async createAlbaran(albaran: Albaran, session: number = null) {
    return await this.generalCreatePedido('albaranes', { albaran, session });
  }

  async createAlbaranWithToken(albaran: Albaran, session: number = null) {
    return await this.generalCreatePedidoWithToken('albaranes', { albaran, session });
  }
  async getAlbaranes(page: number = 0, limit: number = 20) {
    return await this.generalGetAll('albaranes', page, limit);
  }

  async getAlbaranesWithToken(page: number = 0, limit: number = 20) {
    return await this.generalGetAllWithToken('albaranes', page, limit);
  }
  async getAlbaranesByMonth(year, month) {
    return await this.generalGetAllNoParams(`albaranes/date/${year}/${month}`);
  }
  async getSessionByMonth(year, month) {
    return await this.generalGetAllNoParams(`albaranes/session/${year}/${month}`);
  }
  async getSessionByYear(year) {
    return await this.generalGetAllNoParams(`albaranes/session/${year}`);
  }
  async getAlbaran(albaranesId: string) {
    return await this.generalGetById('albaranes', albaranesId);
  }
  async getAlbaranesNotFacturedGrouped(page: number = 0, limit: number = 20, filters = null) {
    return await this.specialGetAll('albaranes/grouped-get', { limit, page, filters: filters ? JSON.stringify(filters) : null });
  }
  async getAlbaranesCurrentSessionGrouped() {
    return await this.generalGetAllNoParams('albaranes/open-get');
  }

  async getAlbaranesCurrentSessionGroupedWithToken() {
    return await this.generalGetAllNoParamsWithToken('albaranes/open-get');
  }
  async editAlbaran(albaran: Albaran) {
    return await this.generalEdit('albaranes', albaran._id, albaran);
  }

  async editAlbaranWithToken(albaran: Albaran) {
    return await this.generalEditWithToken('albaranes', albaran._id, albaran);
  }
  async getAlbaranesBySearch(page: number = 1, limit: number = 50, id: string = null, client: string = null): Promise<any> {
    console.log(this.headers);
    const params = {
      page,
      limit,
      id,
      client
    };
    return await this.specialGetAll('albaranes', params);
  }
  async getStatsAlbaranes(chino: boolean, time: string, repartidor = null, ignoreBooleanChinos = false): Promise<any> {
    const params = {
      chino,
      time,
      repartidor,
      ignoreBooleanChinos
    };
    return await this.specialGetAll('albaranes/statistics/total-uds', params);
  }
  async getStatsAlbaranesFacturado(time: string, repartidor = null): Promise<any> {
    const params = {
      time,
      repartidor
    };
    return await this.specialGetAll('albaranes/statistics/total-facturado', params);
  }
  async getStatsPrecioMedio(time: string, repartidor = null): Promise<any> {
    const params = {
      time,
      repartidor
    };
    return await this.specialGetAll('albaranes/statistics/average-price', params);
  }
  async updateAlbaranesFacturados(albaranes: string[], facturado: boolean) {
    const body = {
      albaranes,
      facturado
    }
    return await this.generalCreate('albaranes/facturar', body);
  }
  async editAlbaranEmpleado(id: string, newRepartidorId: any) {
    return await this.generalEdit('albaranes/new-repartidor', id, newRepartidorId); // newRepartidorId = {empleado: xxxxxxxx}
  }
  async deleteAlbaran(albaranId: string) {
    return await this.generalDelete('albaranes', albaranId);
  }

  async deleteAlbaranWithToken(albaranId: string) {
    return await this.generalDeleteWithToken('albaranes', albaranId);
  }

  async getAlbaranesByUserIdWithFilters(userId: string, deleted: boolean, delivered: boolean,
    fake: boolean, facturado: boolean, currentSession: boolean): Promise<any> {
    return await this.generalGetByIdWithFilters('albaranes/get-by-user-with-filters', userId, {
      deleted,
      delivered,
      fake,
      facturado,
      currentSession
    });
  }

  async getAlbaranesByUserIdWithFiltersWithToken(userId: string, deleted: boolean, delivered: boolean,
    fake: boolean, facturado: boolean, currentSession: boolean): Promise<any> {
    return await this.generalGetByIdWithFiltersWithToken('albaranes/get-by-user-with-filters', userId, {
      deleted,
      delivered,
      fake,
      facturado,
      currentSession
    });
  }

  async getLastAlbaranDateByClientId(idClient: string) {
    return await this.generalGetById('albaranes/last-albaran', idClient);
  }

  async getLastAlbaranDateByClientIdWithToken(idClient: string) {
    return await this.generalGetByIdWithToken('albaranes/last-albaran', idClient);
  }


  async getAlbaranByUserId(userId: string): Promise<any> {
    return await this.generalGetById('albaranes', userId);
  }

  async getAlbaranesByClientIdWithFilters(clientId: string, deleted: boolean, delivered: boolean,
    fake: boolean, facturado: boolean, filters = null): Promise<any> {
    return await this.generalGetByIdWithFilters('albaranes/get-by-client-with-filters', clientId, { deleted, delivered, fake, facturado, filters });
  }

  /////////////////////////////////////////
  //////////////// CARS ////////////////
  /////////////////////////////////////////
  async createCar(car: Car) {
    return await this.generalCreate('cars', car);
  }
  async getCars(page: number = 1, limit: number = 50) {
    return await this.generalGetAll('cars', page, limit);
  }
  async getCar(carId: string) {
    return await this.generalGetById('cars', carId);
  }
  async editCar(car: Car) {
    return await this.generalEdit('cars', car._id, car);
  }
  async deleteCar(carId: string) {
    return await this.generalDelete('cars', carId);
  }

  /////////////////////////////////////////
  //////////////// CARGAS ////////////////
  /////////////////////////////////////////
  async createCarga(carga: Carga) {
    return await this.generalCreate('cargas', carga);
  }
  async createCargasInBatch(cargas: Carga[]) {
    return await this.generalCreate('cargas/batch', cargas);
  }
  async editCargasInBatch(cargas: Carga[]) {
    return await this.generalBulkEdit('cargas/batch', cargas);
  }
  async getCargas(page: number = 1, limit: number = 50) {
    return await this.generalGetAll('cargas', page, limit);
  }
  async acceptCarga(cargaId: string) {
    return await this.generalGetById('cargas/accept', cargaId);
  }
  async getSortedCargas(cargas: any[]) {
    return await this.generalCreate('cargas/sorted-created-at', cargas);
  }
  async getCurrentCargas() {
    return await this.generalGetAllNoParams('cargas/current');
  }
  async getCarga(cargaId: string) {
    return await this.generalGetById('cargas', cargaId);
  }
  async editCarga(carga: Carga) {
    return await this.generalEdit('cargas', carga._id, carga);
  }
  async deleteCarga(cargaId: string) {
    return await this.generalDelete('cargas', cargaId);
  }

  async getFurgonetaCargaByUserId(userId: string) {
    return await this.generalGetById('sessions/info-summarized', userId);
  }

  /////////////////////////////////////////
  //////////////// CONFIG ////////////////
  /////////////////////////////////////////

  async createConfig(config: Config) {
    return await this.generalCreate('configs', config);
  }

  async editConfig(config: Config) {
    return await this.generalEdit('configs', config._id, config);
  }

  async getConfigs() {
    return await this.generalGetAllNoParams('configs');
  }
  /////////////////////////////////////////
  //////////////// FACTURAS GARAJES ////////////////
  /////////////////////////////////////////
  async sendFacturaGaraje() {
    return await this.generalGetAllNoParams('facturas/sendFacturaGarajes')
  }

  async generateFacturaGaraje(body: any) {
    const path = 'facturas/generateFacturaGarajes';
    const token = await this.storage.getItem('accessToken');
    const headersAuth = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
  
    return new Promise(resolve => {
      this.http.post(`${this.globalPath}${path}`, body, { headers: headersAuth, observe: 'response' }).subscribe(
        result => resolve(result),
        error => {
          console.log(error);
          this.utils.showToast(error, 'danger', 3000);
          resolve(false);
        }
      );
    });
  }
  
  /////////////////////////////////////////
  //////////////// FACTURAS ////////////////
  /////////////////////////////////////////
  async createFactura(factura: Factura) {
    return await this.generalCreate('facturas', factura);
  }
  async getFacturas(page: number = 1, limit: number = 50) {
    return await this.generalGetAll('facturas', page, limit);
  }
  async getUnsentFacturas() {
    return await this.generalGetAllNoParams('facturas/not-sent');
  }
  async sendRemainingFacturas() {
    return await this.generalGetAllNoParams('facturas/send-not-sent-facturas');
  }
  async getSearchedFacturas(page: number = 1, limit: number = 50, nameOrDoc: any, desde: any, hasta: any, serieFilter: any) {
    const body = {
      page,
      limit,
      nameOrDoc,
      desde,
      hasta,
      serieFilter
    };
    return await this.specialGetAll('facturas', body);
  }
  async getFactura(facturaId: string) {
    return await this.generalGetById('facturas', facturaId);
  }
  async sendUnsendedFacturas() {
    return await this.generalGetById('facturas/testing', '');
  }
  async downloadFacturasByMonthYear(data: any) {
    return await this.DownloadZip('facturas/download-by-date', data);
  }
  async downloadFacturasByMonthYearGarajes(data: any) {
    return await this.DownloadZip('facturas/download-zip-garaje', data);
  }
  async getListByMonth(year, month) {
    return await this.generalGetAllNoParams(`facturas/date/${year}/${month}`);
  }
  async editFactura(factura: Factura) {
    return await this.generalEdit('facturas', factura._id, factura);
  }
  async deleteFactura(facturaId: string) {
    return await this.generalDelete('facturas', facturaId);
  }
  async downloadFactura(factura: Factura, dateSelected: Date, isNewFactura: boolean) {
    return await this.generalDownload('facturas/generateFactura', {factura, dateSelected, isNewFactura});
  }

  /////////////////////////////////////////
  //////////////// GEOTRACKINGS ////////////////
  /////////////////////////////////////////
  async createGeotracking(geotracking: Geotracking) {
    return await this.generalCreate('facturas', geotracking);
  }
  async getGeotrackings(page: number = 1, limit: number = 50) {
    return await this.generalGetAll('geotrackings', page, limit);
  }
  async getGeotracking(geotrackingId: string) {
    return await this.generalGetById('geotrackings', geotrackingId);
  }
  async editGeotracking(geotracking: Geotracking) {
    return await this.generalEdit('geotrackings', geotracking._id, geotracking);
  }
  async deleteGeotracking(geotrackingsId: string) {
    return await this.generalDelete('geotrackings', geotrackingsId);
  }

  /////////////////////////////////////////
  //////////////// LOCATIONS //////////
  /////////////////////////////////////////

  async getLocationsBySessionId(id: string) {
    return await this.generalGetById('locations/by-session', id);
  }
  /////////////////////////////////////////
  //////////////// NOTIFICATIONS //////////
  /////////////////////////////////////////

  async createNotifications(ids: string[], type: string) {
    return await this.generalCreate('notifications', { ids, type });
  }

  /////////////////////////////////////////
  //////////////// PRODUCTS ////////////////
  /////////////////////////////////////////
  async createProduct(product: Product) {
    return await this.generalCreate('products', product);
  }
  async getProducts(page: number = 1, limit: number = 50) {
    return await this.generalGetAll('products', page, limit);
  }

  async getProductsWithToken(page: number = 1, limit: number = 50) {
    return await this.generalGetAllWithToken('products', page, limit);
  }
  async getProduct(productId: string) {
    return await this.generalGetById('products', productId);
  }
  async editProduct(product: Product) {
    return await this.generalEdit('products', product._id, product);
  }
  async deleteProduct(productId: string) {
    return await this.generalDelete('products', productId);
  }

  /////////////////////////////////////////
  //////////////// PROMOCIONES ////////////////
  /////////////////////////////////////////
  async createPromotion(promotion: Promotion) {
    return await this.generalCreate('promociones', promotion);
  }
  async getPromotiones(page: number = 1, limit: number = 50) {
    return await this.generalGetAll('promociones', page, limit);
  }
  async getPromotion(promotionId: string) {
    return await this.generalGetById('promociones', promotionId);
  }
  async editPromotion(promotion: Promotion) {
    return await this.generalEdit('promociones', promotion._id, promotion);
  }
  async deletePromotion(promotionId: string) {
    return await this.generalDelete('promociones', promotionId);
  }

  /////////////////////////////////////////
  //////////////// SESSIONS ////////////////
  /////////////////////////////////////////
  async createSession(sessions: Session) {
    return await this.generalCreate('sessions', sessions);
  }
  async getSessions(page: number = 1, limit: number = 50, from: Date = null, until: Date = null) {
    const body = {
      page,
      limit,
      from,
      until
    };
    return await this.specialGetAll('sessions', body);
  }

  async getSessionsByMonth(year, month) {
    return await this.generalGetAllNoParams(`sessions/date/${year}/${month}`);
  }

  async getInfoSession(cargas: any, userId: string) {
    const body = {
      cargas,
      userId
    };
    return await this.generalCreate('sessions/info', body);
  }
  async closeSession(id: string, currentSessionAlbs: any[], summary: any, currentUserId: string) {
    const body = {
      id,
      currentSessionAlbs,
      summary,
      currentUserId
    };
    return await this.generalEdit('sessions/close', id, body);
  }
  async returnFurgoneta(id: string, lastSessionCargas: any, summary: any, currentSessionAlbs: any, userId: string) {
    const body = {
      lastSessionCargas,
      summary,
      currentSessionAlbs,
      userId
    };
    return await this.generalEdit('sessions/return', id, body);
  }
  async getSession(sessionId: string) {
    return await this.generalGetById('sessions', sessionId);
  }
  async getAllAlbaranesGroupedBySession() {
    return await this.generalGetAllNoParams('sessions/grouped');
  }
  async getOpenSessions() {
    return await this.generalGetAllNoParams('sessions/open');
  }
  async getCurrentSessionAlbs(userId: string) {
    return await this.generalGetById('sessions/current-albs', userId);
  }
  async editSession(session: Session) {
    return await this.generalEdit('sessions', session._id, session);
  }
  async deleteSession(sessionId: string) {
    return await this.generalDelete('sessions', sessionId);
  }

  /////////////////////////////////////////
  //////////////// STOCKS ////////////////
  /////////////////////////////////////////
  async createStock(stock: Stock) {
    return await this.generalCreate('stocks', stock);
  }
  async getStocks(page: number = 1, limit: number = 50) {
    return await this.generalGetAll('stocks', page, limit);
  }
  async getAllStockList(page: number = 0, limit: number = 20) {
    return await this.specialGetAll('stocks/all', { page, limit });
  }
  async getStock(stockId: string) {
    return await this.generalGetById('stocks', stockId);
  }
  async editStock(stock: Stock) {
    return await this.generalEdit('stocks', stock._id, stock);
  }
  async editStockInBatch(stock: Stock[]) {
    return await this.generalCreate('stocks/batch', stock);
  }
  async deleteStock(stockId: string) {
    return await this.generalDelete('stocks', stockId);
  }

  /////////////////////////////////////////
  //////////////// SETTINGS ////////////////
  /////////////////////////////////////////
  async createSetting(setting: Setting) {
    return await this.generalCreate('settings', setting);
  }
  async getSettings() {
    return await this.generalGetAllNoParams('settings');
  }
  async getSetting(settingId: string) {
    return await this.generalGetById('settings', settingId);
  }
  async editSetting(setting: Setting) {
    return await this.generalEdit('settings', setting._id, setting);
  }
  /////////////////////////////////////////
  //////////////// ESTADÍSTICAS ///////////
  /////////////////////////////////////////
  async getRangeStatistics(startDate: string, endDate: string) {
    return await this.generalCreate('albaranes/statistics/complete-excel', {startDate, endDate});
  }

  /////////////////////////////////////////
  //////////////// TARIFAS ////////////////
  /////////////////////////////////////////
  async createTarifa(tarifa: Tarifa) {
    return await this.generalCreate('tarifas', tarifa);
  }
  async getTarifas(page: number = 1, limit: number = 500) {
    return await this.generalGetAll('tarifas', page, limit);
  }
  async getTarifa(tarifaId: string) {
    return await this.generalGetById('tarifas', tarifaId);
  }
  async getDefaultTarifas() {
    return await this.generalGetAllNoParams('tarifas/default');
  }
  async getDefaultTarifasWithToken() {
    return await this.generalGetAllNoParamsWithToken('tarifas/default');
  }
  async editTarifa(tarifa: Tarifa) {
    return await this.generalEdit('tarifas', tarifa._id, tarifa);
  }
  async deleteTarifa(tarifaId: string) {
    return await this.generalDelete('tarifas', tarifaId);
  }

  /////////////////////////////////////////
  //////////////// ZONAS ////////////////
  /////////////////////////////////////////
  async createZona(zona: Zona) {
    return await this.generalCreate('zonas', zona);
  }
  async getZonas(page: number = 1, limit: number = 50) {
    return await this.generalGetAll('zonas', page, limit);
  }
  async getZona(zonaId: string) {
    return await this.generalGetById('zonas', zonaId);
  }
  async editZona(zona: Zona) {
    return await this.generalEdit('zonas', zona._id, zona);
  }
  async deleteZona(zonaId: string) {
    return await this.generalDelete('zonas', zonaId);
  }
  /////////////////////////////////////////
  //////////////// GESTION - NOTICE ////////////////
  /////////////////////////////////////////
  async createGestion(gestion: Gestion) {
    return await this.generalCreate('gestiones', gestion);
  }
  async getGestiones(page: number = 1, limit: number = 50) {
    return await this.generalGetAll('gestiones', page, limit);
  }

  async getGestionesWithToken(page: number = 1, limit: number = 50) {
    return await this.generalGetAllWithToken('gestiones', page, limit);
  }
  async getGestion(gestionId: string) {
    return await this.generalGetById('gestiones', gestionId);
  }

  async getGestionWithToken(gestionId: string) {
    return await this.generalGetByIdWithToken('gestiones', gestionId);
  }
  async editGestion(gestion: Gestion) {
    return await this.generalEdit('gestiones', gestion._id, gestion);
  }
  /*async deleteGestion(gestionId: string) {
    return await this.generalDelete('gestiones', gestionId);
  }*/


   /////////////////////////////////////////
  //////////////// CONTACT ////////////////
  /////////////////////////////////////////
  async createContact(newContact: any) {
    return await this.generalCreate('contacts', newContact);
  }


  /////////////////////////////////////////
  //////////////// GENERAL ////////////////
  /////////////////////////////////////////
  async generalCreate(path, body: any): Promise<any> {
    const token = await this.storage.getItem('accessToken');
    const headersAuth = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);
    return new Promise(async resolve => {
      this.http.post(this.globalPath + path, body, { headers: headersAuth, observe: 'response' }).subscribe(
        result => {
          this.utils.showToast('Guardado con exito');
          resolve(result);
        },
        async error => {
          console.log(error);
          this.utils.showToast(error.error.error, 'danger', 3000);
          resolve(false);
        }
      );
    });
  }

  async generalCreatePedido(path, body: any): Promise<any> {
    const token = await this.storage.getItem('accessToken');
    const headersAuth = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);
    return new Promise(async resolve => {
      this.http.post(this.globalPath + path, body, { headers: headersAuth, observe: 'response' }).subscribe(
        result => {
          this.utils.showToast('Pedido creado con exito. El repartidor llegará lo antes posible', 'success', 6000);
          resolve(result);
        },
        async error => {
          console.log(error);
          this.utils.showToast(error.error.error, 'danger', 3000);
          resolve(false);
        }
      );
    });
  }

  async generalCreatePedidoWithToken(path, body: any): Promise<any> {
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFsdmFybyIsImlhdCI6MTcxNDMyNTYwOSwiZXhwIjo0ODcwMDg1NjA5fQ.pa0_jP-_H00TmEOtVZaiJC6BYvBqwnkZmV9JO4hlVXU'
    const headersAuth = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);
    return new Promise(async resolve => {
      this.http.post(this.globalPath + path, body, { headers: headersAuth, observe: 'response' }).subscribe(
        result => {
          this.utils.showToast('Pedido creado con exito. El repartidor llegará lo antes posible', 'success', 6000);
          resolve(result);
        },
        async error => {
          console.log(error);
          this.utils.showToast(error.error.error, 'danger', 3000);
          resolve(false);
        }
      );
    });
  }



  async generalGetAll(path: string, page: number, limit: number) {
    const token = await this.storage.getItem('accessToken');
    const headersAuth = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);
    const body = {
      page,
      limit
    };
    return new Promise(async resolve => {
      console.log(`${this.globalPath}${path}/filters`);
      this.http.post(`${this.globalPath}${path}/filters`, body, { headers: headersAuth, observe: 'response' }).subscribe(
        result => (resolve(result)),
        async error => {
          console.log(error);
          this.utils.showToast(error, 'danger', 3000);
          resolve(false);
        }
      );
    });
  }


  async generalGetAllWithToken(path: string, page: number, limit: number) {
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFsdmFybyIsImlhdCI6MTcxNDMyNTYwOSwiZXhwIjo0ODcwMDg1NjA5fQ.pa0_jP-_H00TmEOtVZaiJC6BYvBqwnkZmV9JO4hlVXU';
    const headersAuth = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);
    const body = {
      page,
      limit
    };
    return new Promise(async resolve => {
      console.log(`${this.globalPath}${path}/filters`);
      this.http.post(`${this.globalPath}${path}/filters`, body, { headers: headersAuth, observe: 'response' }).subscribe(
        result => (resolve(result)),
        async error => {
          console.log(error);
          this.utils.showToast(error, 'danger', 3000);
          resolve(false);
        }
      );
    });
  }


  async specialGetAll(path: string, params: any) {
    const token = await this.storage.getItem('accessToken');
    const headersAuth = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);
    return new Promise(async resolve => {
      this.http.get(this.globalPath + path, { headers: headersAuth, observe: 'response', params }).subscribe(
        result => (resolve(result)),
        async error => {
          console.log('AQUIIIII');
          console.log(error);
          if (error.status !== 200) {
            this.utils.showToast(error, 'danger', 3000);
          }
          resolve(false);
        }
      );
    });
  }

  async generalGetAllNoParams(path: string) {
    const token = await this.storage.getItem('accessToken');
    const headersAuth = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);
    console.log(`${this.globalPath}${path}`);
    return new Promise(async resolve => {
      this.http.get(`${this.globalPath}${path}`, { headers: headersAuth, observe: 'response' }).subscribe(
        result => (resolve(result)),
        async error => {
          console.log(error);
          this.utils.showToast(error, 'danger', 3000);
          resolve(false);
        }
      );
    });
  }

  async generalGetAllNoParamsWithToken(path: string) {
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFsdmFybyIsImlhdCI6MTcxNDMyNTYwOSwiZXhwIjo0ODcwMDg1NjA5fQ.pa0_jP-_H00TmEOtVZaiJC6BYvBqwnkZmV9JO4hlVXU'
    const headersAuth = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);
    console.log(`${this.globalPath}${path}`);
    return new Promise(async resolve => {
      this.http.get(`${this.globalPath}${path}`, { headers: headersAuth, observe: 'response' }).subscribe(
        result => (resolve(result)),
        async error => {
          console.log(error);
          this.utils.showToast(error, 'danger', 3000);
          resolve(false);
        }
      );
    });
  }

  async generalGetById(path: string, id: string) {
    const token = await this.storage.getItem('accessToken');
    const headersAuth = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);
    return new Promise(async resolve => {
      this.http.get(this.globalPath + path + '/' + id, { headers: headersAuth, observe: 'response' }).subscribe(
        result => (resolve(result)),
        async error => {
          if (Object.keys(error).length > 1) {
            console.log(error);
            this.utils.showToast(error.error.error, 'danger', 3000);
          } else {
            console.log(error);
            this.utils.showToast(error, 'danger', 3000);
          }
          resolve(false);
        }
      );
    });
  }
  

  async generalGetByIdWithToken(path: string, id: string) {
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFsdmFybyIsImlhdCI6MTcxNDMyNTYwOSwiZXhwIjo0ODcwMDg1NjA5fQ.pa0_jP-_H00TmEOtVZaiJC6BYvBqwnkZmV9JO4hlVXU'
    const headersAuth = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);
    return new Promise(async resolve => {
      this.http.get(this.globalPath + path + '/' + id, { headers: headersAuth, observe: 'response' }).subscribe(
        result => (resolve(result)),
        async error => {
          if (Object.keys(error).length > 1) {
            console.log(error);
            this.utils.showToast(error.error.error, 'danger', 3000);
          } else {
            console.log(error);
            this.utils.showToast(error, 'danger', 3000);
          }
          resolve(false);
        }
      );
    });
  }

  async generalGetByIdWithFilters(path: string, id: string, params: any) {
    const token = await this.storage.getItem('accessToken');
    const headersAuth = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);
    return new Promise(async resolve => {
      this.http.get(this.globalPath + path + '/' + id, { headers: headersAuth, observe: 'response', params }).subscribe(
        result => (resolve(result)),
        async error => {
          console.log(error);
          this.utils.showToast(error, 'danger', 3000);
          resolve(false);
        }
      );
    });
  }

  async generalGetByIdWithFiltersWithToken(path: string, id: string, params: any) {
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFsdmFybyIsImlhdCI6MTcxNDMyNTYwOSwiZXhwIjo0ODcwMDg1NjA5fQ.pa0_jP-_H00TmEOtVZaiJC6BYvBqwnkZmV9JO4hlVXU'
    const headersAuth = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);
    return new Promise(async resolve => {
      this.http.get(this.globalPath + path + '/' + id, { headers: headersAuth, observe: 'response', params }).subscribe(
        result => (resolve(result)),
        async error => {
          console.log(error);
          this.utils.showToast(error, 'danger', 3000);
          resolve(false);
        }
      );
    });
  }

  async generalGetByName(path: string, name: string, nonPrimary: string) {
    const token = await this.storage.getItem('accessToken');
    const headersAuth = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);
    return new Promise(async resolve => {
      this.http.get(this.globalPath + path, { headers: headersAuth, observe: 'response', params: { name, nonPrimary } }).subscribe(
        result => (resolve(result)),
        async error => {
          console.log(error);
          this.utils.showToast(error, 'danger', 3000);
          resolve(false);
        }
      );
    });
  }

  async generalGetByNameWithToken(path: string, name: string, nonPrimary: string) {
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFsdmFybyIsImlhdCI6MTcxNDMyNTYwOSwiZXhwIjo0ODcwMDg1NjA5fQ.pa0_jP-_H00TmEOtVZaiJC6BYvBqwnkZmV9JO4hlVXU'
    const headersAuth = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);
    return new Promise(async resolve => {
      this.http.get(this.globalPath + path, { headers: headersAuth, observe: 'response', params: { name, nonPrimary } }).subscribe(
        result => (resolve(result)),
        async error => {
          console.log(error);
          this.utils.showToast(error, 'danger', 3000);
          resolve(false);
        }
      );
    });
  }


  async generalEdit(path: string, id: string, body: any) {
    const token = await this.storage.getItem('accessToken');
    const headersAuth = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);
    return new Promise(async resolve => {
      this.http.patch(this.globalPath + path + '/' + id, body, { headers: headersAuth, observe: 'response' }).subscribe(
        result => (resolve(result)),
        async error => {
          console.log(error);
          this.utils.showToast(error, 'danger', 3000);
          resolve(false);
        }
      );
    });
  }

  async generalEditWithToken(path: string, id: string, body: any) {
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFsdmFybyIsImlhdCI6MTcxNDMyNTYwOSwiZXhwIjo0ODcwMDg1NjA5fQ.pa0_jP-_H00TmEOtVZaiJC6BYvBqwnkZmV9JO4hlVXU';
    const headersAuth = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);
    return new Promise(async resolve => {
      this.http.patch(this.globalPath + path + '/' + id, body, { headers: headersAuth, observe: 'response' }).subscribe(
        result => (resolve(result)),
        async error => {
          console.log(error);
          this.utils.showToast(error, 'danger', 3000);
          resolve(false);
        }
      );
    });
  }

  async generalBulkEdit(path: string, body: any) {
    const token = await this.storage.getItem('accessToken');
    const headersAuth = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);
    return new Promise(async resolve => {
      this.http.patch(this.globalPath + path, body, { headers: headersAuth, observe: 'response' }).subscribe(
        result => (resolve(result)),
        async error => {
          console.log(error);
          this.utils.showToast(error, 'danger', 3000);
          resolve(false);
        }
      );
    });
  }

  async generalDelete(path: string, id: string) {
    const token = await this.storage.getItem('accessToken');
    const headersAuth = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);
    return new Promise(async resolve => {
      this.http.delete(this.globalPath + path + '/' + id, { headers: headersAuth, observe: 'response' }).subscribe(
        result => (resolve(result)),
        async error => {
          console.log(error);
          this.utils.showToast(error, 'danger', 3000);
          resolve(false);
        }
      );
    });
  }

  async generalDeleteWithToken(path: string, id: string) {
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFsdmFybyIsImlhdCI6MTcxNDMyNTYwOSwiZXhwIjo0ODcwMDg1NjA5fQ.pa0_jP-_H00TmEOtVZaiJC6BYvBqwnkZmV9JO4hlVXU';
    const headersAuth = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);
    return new Promise(async resolve => {
      this.http.delete(this.globalPath + path + '/' + id, { headers: headersAuth, observe: 'response' }).subscribe(
        result => (resolve(result)),
        async error => {
          console.log(error);
          this.utils.showToast(error, 'danger', 3000);
          resolve(false);
        }
      );
    });
  }

  async generalDownload(path: string, data: any) {
    let factura: any;

    factura = data.factura ? data.factura : data;
    const token = await this.storage.getItem('accessToken');
    console.log(token);
    const headersAuth = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);
    return new Promise(async resolve => {
      this.http.get(this.globalPath + path + '/' + factura._id, {
        responseType: 'blob',
        headers: headersAuth,
        observe: 'response',
        params: { dateSelected: data.dateSelected ? data.dateSelected.toDateString() : new Date().toDateString(),
                  isNewFactura: data.isNewFactura
                }
      }).subscribe(
        result => {
          console.log(result.body);
          const data = window.URL.createObjectURL(result.body);
          const link = document.createElement('a');
          link.href = data;
          const serie = factura.serie;
          const clientId = factura.id;
          const year = new Date().getFullYear();
          const name = factura.nombre;

          // FACTURA H1-328-2020 MAR 7X24 POZUELO
          link.download = `FACTURA H${serie}-${clientId}-${year} ${name}.pdf`;

          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            })
          );

          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);

          resolve(result.body)
        },
        async error => {
          console.log(error);
          // this.utils.showToast(error, 'danger', 3000);
          this.utils.showToast('Vuelva a pulsar descargar', 'danger', 3000);
          resolve(false);
        }
      );
    });
  }

  async DownloadZip(path: string, sendData) {
    const token = await this.storage.getItem('accessToken');
    console.log(token, sendData);
    const headersAuth = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);
    return new Promise(async resolve => {
      this.http.get(this.globalPath + path + '?month=' + sendData.month + '&year=' + sendData.year, {
        responseType: 'blob',
        headers: headersAuth,
        observe: 'response'
      }).subscribe(
        result => {
          console.log('result.body', result.body);
          if (!result.body || !result.body.size || result.body.size <= 22) {
            this.utils.showToast('No se encontró ninguna factura', 'danger', 3000);
            resolve(false);
            return
          }
          const data = window.URL.createObjectURL(result.body);
          const link = document.createElement('a');
          link.href = data;
          const currentDateFolder = new Date(sendData.year, sendData.month - 1);
          const monthFolder = new Intl.DateTimeFormat('en', { month: 'short' }).format(currentDateFolder);
          const yearFolder = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(currentDateFolder);

          link.download = monthFolder + '_' + yearFolder + '.zip';

          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            })
          );

          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);

          resolve(result.body)
        },
        async error => {
          console.log(error);
          this.utils.showToast(error, 'danger', 3000);
          resolve(false);
        }
      );
    });
  }
}
