import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MenuGuard } from './guard/menu/menu.guard';
import { ManagerGuard } from './guard/manager.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'clientes',
    loadChildren: () => import('./pages/clientes/clientes.module').then(m => m.ClientesPageModule)
  },
  {
    path: 'cliente/:id',
    loadChildren: () => import('./pages/cliente-detail/cliente-detail.module').then(m => m.ClienteDetailPageModule),
    canActivate: [MenuGuard]
  },
  {
    path: 'new-client',
    loadChildren: () => import('./pages/new-client/new-client.module').then(m => m.NewClientPageModule),
    canActivate: [MenuGuard]
  },
  {
    path: 'tarifas',
    loadChildren: () => import('./pages/tarifas/tarifas.module').then(m => m.TarifasPageModule),
    canActivate: [MenuGuard]
  },
  {
    path: 'promotions',
    loadChildren: () => import('./pages/promotions/promotions.module').then(m => m.PromotionsPageModule),
    canActivate: [MenuGuard]
  },
  {
    path: 'stock',
    loadChildren: () => import('./pages/stock/stock.module').then(m => m.StockPageModule),
    canActivate: [MenuGuard]
  },
  {
    path: 'close-session',
    loadChildren: () => import('./pages/close-session/close-session.module').then(m => m.CloseSessionPageModule)
  },
  {
    path: 'albaranes',
    loadChildren: () => import('./pages/albaranes/albaranes.module').then(m => m.AlbaranesPageModule),
    canActivate: [MenuGuard]
  },
  {
    path: 'albaran-detail',
    loadChildren: () => import('./pages/albaran-detail/albaran-detail.module').then(m => m.AlbaranDetailPageModule),
    canActivate: [MenuGuard]
  },
  {
    path: 'facturas',
    loadChildren: () => import('./pages/facturas/facturas.module').then(m => m.FacturasPageModule),
    canActivate: [MenuGuard]
  },
  {
    path: 'failed-emails',
    loadChildren: () => import('./pages/failed-emails/failed-emails.module').then(m => m.FailedEmailsPageModule),
    canActivate: [MenuGuard]
  },
  {
    path: 'factura-detail',
    loadChildren: () => import('./pages/factura-detail/factura-detail.module').then(m => m.FacturaDetailPageModule),
    canActivate: [MenuGuard]
  },
  {
    path: 'zonas',
    loadChildren: () => import('./pages/zonas/zonas.module').then(m => m.ZonasPageModule),
    canActivate: [MenuGuard]
  },
  {
    path: 'control',
    loadChildren: () => import('./pages/gestion/gestion.module').then(m => m.GestionPageModule),
    canActivate: [MenuGuard]
  },
  {
    path: 'qrcontrol/:id',
    loadChildren: () => import('./pages/qrcontrol/qrcontrol.module').then(m => m.QrcontrolModule)
  },
  {
    path: 'cargas',
    loadChildren: () => import('./pages/cargas/cargas.module').then(m => m.CargasPageModule),
    canActivate: [MenuGuard]
  },
  {
    path: 'tarifa-detail',
    loadChildren: () => import('./pages/tarifa-detail/tarifa-detail.module').then(m => m.TarifaDetailPageModule),
    canActivate: [MenuGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then(m => m.UsersPageModule),
    canActivate: [
      MenuGuard,
      ManagerGuard
    ]
  },
  {
    path: 'cars',
    loadChildren: () => import('./pages/cars/cars.module').then(m => m.CarsPageModule),
    canActivate: [MenuGuard]
  },
  {
    path: 'map',
    loadChildren: () => import('./pages/map/map.module').then(m => m.MapPageModule),
    canActivate: [MenuGuard]
  },
  {
    path: 'gestion',
    loadChildren: () => import('./pages/gestion/gestion.module').then(m => m.GestionPageModule),
    canActivate: [MenuGuard]
  },
  {
    path: 'map-details/:id',
    loadChildren: () => import('./pages/map-details/map-details.module').then( m => m.MapDetailsPageModule)
  },
  {
    path: 'ajustes',
    loadChildren: () => import('./pages/ajustes/ajustes.module').then( m => m.AjustesPageModule)
  },
  {
    path: 'estadisticas',
    loadChildren: () => import('./pages/estadisticas/estadisticas.module').then( m => m.EstadisticasPageModule)
  },
  {
    path: 'crear-pedido',
    loadChildren: () => import('./pages/crear-pedido/crear-pedido.module').then( m => m.CrearPedidoPageModule)
  },
  {
    path: 'finalizar-pedido',
    loadChildren: () => import('./pages/finalizar-pedido/finalizar-pedido.module').then( m => m.FinalizarPedidoPageModule)
  },
  {
    path: 'pedido',
    loadChildren: () => import('./pages/pedido/pedido.module').then( m => m.PedidoPageModule)
  },  
  {
    path: 'invitado',
    loadChildren: () => import('./pages/invitado/invitado.module').then( m => m.InvitadoPageModule)
  },
  {
    path: 'garaje',
    loadChildren: () => import('./pages/garaje/garaje.module').then( m => m.GarajePageModule)
  },
  {
    path: 'garaje/new',
    loadChildren: () => import('./pages/cliente-garajes-detail.page/cliente-garajes-detail.module').then( m => m.ClienteGarajesDetailPageModule)
  },
  {
    path: 'garaje/:id',
    loadChildren: () => import('./pages/cliente-garajes-detail.page/cliente-garajes-detail.module').then(m => m.ClienteGarajesDetailPageModule),
    canActivate: [MenuGuard]
  }
  // {
  //   path: 'landing',
  //   loadChildren: () => import('./pages/landing/landing.module').then( m => m.LandingPageModule)
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
