import { Injectable } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscribeDataService {
  menuStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private menu: MenuController) { }

  async checkMenuStatus() {
    const menuStatus = await this.menu.isOpen();
    this.menuStatus.next(menuStatus);
  }

  observeMenuStatus(): Observable<boolean> {
    return this.menuStatus.asObservable();
  }
}
