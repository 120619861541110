import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { GestionPage } from 'src/app/pages/gestion/gestion.page';

@Component({
  selector: 'app-mobile-control-buttons',
  templateUrl: './mobile-control-buttons.component.html',
  styleUrls: ['./mobile-control-buttons.component.scss'],
})
export class MobileControlButtonsComponent implements OnInit {
  @Input() mapaView: boolean;
  @Input() generacionView: boolean;
  @Input() controlView: boolean;
  //@Output() viewOn: EventEmitter<string> = new EventEmitter<string>();
  @Output() viewOn: any = new EventEmitter();

  selectedViews: string[] = [];

  constructor(private gestion: GestionPage) {

   }

   gestionResetMobiles(){
    this.gestion.resetForm();
   }

  ngOnInit() {
  }

  toggleView(view: string) {
    // Verifica si la vista está seleccionada y la quita, o la agrega si no está seleccionada.
    if (this.selectedViews.includes(view)) {
      this.selectedViews = this.selectedViews.filter((v) => v !== view);
    } else {
      this.selectedViews.push(view);
    }

    // Emite el conjunto de vistas seleccionadas.
    this.viewOn.emit(this.selectedViews);
  }

  toggleSection(section: string) {
    if (section === 'mapaView') {
      this.mapaView = !this.mapaView;
    } else if (section === 'generacionView') {
      this.generacionView = !this.generacionView;
    } else if (section === 'controlView') {
      this.controlView = !this.controlView;
    }
    // Emitir el evento viewOn con el valor seleccionado
    this.viewOn.emit(section);
  }

  saveValue(ev: any) {
    // Esta función no tiene que hacer nada en este componente, ya que se maneja en el componente principal.
  }

}
