import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { SubscribeDataService } from 'src/app/services/subscribe-data/subscribe-data.service';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent {
  @Input()
  buttonActive = 'clientes';
  @Input()
  menuIsOpen: boolean;
  isQrControlRoute: boolean = false;  

  constructor(
    private menu: MenuController,
    private subsDataService: SubscribeDataService,
    private router: Router) {

      this.isQrControlRoute = this.isCurrentRouteQrControl();
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isQrControlRoute = this.isCurrentRouteQrControl();
      }
    });
  }

  async toogleMenu() {
    if (this.menuIsOpen) {
      await this.menu.close();
    } else {
      await this.menu.open();
    }
    await this.subsDataService.checkMenuStatus();
  }

  async menuStatus() {
    const resp: boolean = await this.menu.isOpen();
    this.menuIsOpen = resp;
    return resp;
  }

  private isCurrentRouteQrControl(): boolean {
    const urlSegments = this.router.url.split('/');
    return urlSegments.length >= 2 && urlSegments[1] === 'qrcontrol';
  }

}
