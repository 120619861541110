import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import { TableComponent } from './table/table.component';
import { AddStockComponent } from './add-stock/add-stock.component';
import { AddCargaComponent } from './add-carga/add-carga.component';
import { PaidBoxComponent } from './paid-box/paid-box.component';
import { EditTableComponent } from './edit-table/edit-table.component';
import { SessionSummaryComponent } from './session-summary/session-summary.component';
import { AcceptCargaComponent } from './accept-carga/accept-carga.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { MobileHeaderComponent } from './mobile-header/mobile-header.component';
import { LiteMenuComponent } from './lite-menu/lite-menu.component';
import { MobileTableComponent } from './mobile-table/mobile-table.component';
import { MobileControlButtonsComponent } from './mobile-control-buttons/mobile-control-buttons.component';
import { ControlViewMobileComponent } from './control-view-mobile/control-view-mobile.component';
import { MoveAlbaranComponent } from './move-albaran/move-albaran.component';

@NgModule({
  declarations: [
    MenuComponent,
    TableComponent,
    AddStockComponent,
    AddCargaComponent,
    PaidBoxComponent,
    SessionSummaryComponent,
    AcceptCargaComponent,
    EditTableComponent,
    MobileMenuComponent,
    MobileHeaderComponent,
    LiteMenuComponent,
    MobileTableComponent,
    MobileControlButtonsComponent,
    ControlViewMobileComponent,
    MoveAlbaranComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule
  ],
  exports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    MenuComponent,
    TableComponent,
    AddStockComponent,
    AddCargaComponent,
    PaidBoxComponent,
    AcceptCargaComponent,
    SessionSummaryComponent,
    EditTableComponent,
    MobileMenuComponent,
    MobileHeaderComponent,
    LiteMenuComponent,
    MobileTableComponent,
    MobileControlButtonsComponent,
    ControlViewMobileComponent,
    MoveAlbaranComponent
  ],
  entryComponents: [
  ]
})
export class ComponentModule { }
