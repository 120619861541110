import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RestService } from 'src/app/services/rest/rest.service';
import { MoveAlbaranComponent } from '../move-albaran/move-albaran.component';

@Component({
  selector: 'app-control-view-mobile',
  templateUrl: './control-view-mobile.component.html',
  styleUrls: ['./control-view-mobile.component.scss'],
})
export class ControlViewMobileComponent implements OnChanges {
  albaranes: any;
  repartidorOpen: any;
  @Input()
  repartidores: any = [];
  @Input()
  fakeRepartidor: any;
  @Input()
  repartidorData: any;
  @Input()
  gestionCard: any;
  @Input()
  gestionFakeCard: any;

  @Output()
  cargaRepartidorInfo: any = new EventEmitter();
  @Output()
  clientInfo: any = new EventEmitter();
  @Output()
  deleteAlbaran: any = new EventEmitter();
  @Output()
  viewRepartidorData: any = new EventEmitter();
  @Output()
  drop: any = new EventEmitter();
  
  constructor(
    private rest: RestService,
    private modal: ModalController
  ) { }

  ngOnChanges() {
    if (this.fakeRepartidor && (this.repartidores.length > 0 &&
      this.repartidores[0].firstName !== 'Sin repartidor asignado')) {
      this.fakeRepartidor.firstName = 'Sin repartidor asignado';
      this.fakeRepartidor.lastName = '';
      this.repartidores.unshift(this.fakeRepartidor);
    }
    if (this.gestionCard) {
      console.log(this.gestionCard);
    }
  }

  async getAlbaranes() {
    const result: any = await this.rest.getAlbaranes();
    console.log(result.body.records);
    this.albaranes = result.body.records;
    this.albaranes.forEach(albaran => {
      if (albaran.empleado.firstName === 'usuarioFake') {
        albaran.empleado.firstName = 'Albaran sin asignar a ningún repartidor',
          albaran.empleado.lastName = ''
      }
    });
  }

  selectRepartidor() {
    this.repartidorOpen = parseInt(this.repartidorOpen, 10);
  }

  async move(card: any, cardIndex: number, cards: any, repartidorId: string) {
    const modal = await this.modal.create({
      component: MoveAlbaranComponent,
      cssClass: 'moveAlbaran',
      componentProps: {
        repartidores: this.repartidores,
        cardIndex: cardIndex,
        card: card,
        cards: cards,
        repartidorId: repartidorId
      }
    });
    await modal.present();
    const result: any = await modal.onWillDismiss();
    if (result && result.data) {
      this.drop.emit(result.data);
    }
  }

}
