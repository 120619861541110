import { Injectable } from '@angular/core';
import { User } from 'src/app/interfaces/user';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private storage: Storage) { }

  setItem(key: string, value) {
    this.storage.set(key, value);
  }

  async getItem(key: string) {
    return await this.storage.get(key);
  }

  async removeItem(key: string) {
    return await this.storage.remove(key);
  }

  async setUser(user: User): Promise<void> {
    return await this.storage.set('currentUser', user);
  }

  async getUser(): Promise<User> {
    return await this.storage.get('currentUser');
  }

  async logOut(): Promise<string> {
    return await this.storage.remove('currentUser');
  }
}
