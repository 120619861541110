import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-move-albaran',
  templateUrl: './move-albaran.component.html',
  styleUrls: ['./move-albaran.component.scss'],
})
export class MoveAlbaranComponent implements OnInit {
  selectedRepartidor: any;
  @Input()
  repartidores: any;
  @Input()
  cardIndex: any;
  @Input()
  card: any;
  @Input()
  cards: any;
  @Input()
  repartidorId: string;

  constructor(private modal: ModalController) { }

  ngOnInit() {
    if (this.repartidorId) {
      this.selectedRepartidor = this.repartidorId;
    }
  }

  cancel() { }

  close() {
    this.modal.dismiss();
  }

  async editAlbaran() {
    const sendAlbaran = {
      orderIndex: this.cardIndex,
      _id: this.card.clienteInfo._id,
      empleado: this.selectedRepartidor,
      oldCard: this.card,
      newCard: this.card
    };
    this.modal.dismiss(sendAlbaran);
  }

}
