import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { Client } from 'src/app/interfaces/client';
import { Product } from 'src/app/interfaces/product';
import { Albaran } from 'src/app/interfaces/albaran';
import { ProductByNAME, ProductById, ProductInitialsByNAME } from 'src/app/data/products';
import { isNullOrUndefined } from 'util';
import { Router, ChildActivationStart } from '@angular/router';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { AlertController, ModalController } from '@ionic/angular';
import {
  GoogleMapOptions, GoogleMapsMapTypeId, GoogleMaps, ILatLng, Polyline, Marker,
  GoogleMapsEvent, GoogleMap, LatLng
} from '@ionic-native/google-maps';
import { SessionSummaryComponent } from 'src/app/components/session-summary/session-summary.component';

declare var google;

@Component({
  selector: 'app-gestion',
  templateUrl: './gestion.page.html',
  styleUrls: ['./gestion.page.scss'],
})
export class GestionPage implements OnInit {
  @ViewChild('search') myInput;
  coordinates = [];
  markers = [];
  @ViewChild('map', { static: false }) mapElement: ElementRef;
  map: any;
  destinationGoogleMaps = '';

  showAllFields = false;

  todo = [
    'Get to work',
    'Pick up groceries',
    'Go home',
    'Fall asleep'
  ];

  done = [
    'Get up',
    'Brush teeth',
    'Take a shower',
    'Check e-mail',
    'Walk dog'
  ];

  searchClient: string;
  extraNotesField: string;
  repartidorField = '5f0c2b821d2f0703ebf97e46';
  fields = {};
  dateField: string;
  timeField: string;
  data = [];
  category: object;

  userAlbs: any;
  userCargas: any;
  delivered = false;
  selectedView = 'main';

  clientSelected: any;
  clientChanged = false;
  clients: Client[] = [];
  clientField: string;
  tarifasClient: any;
  productsClient: any;
  products: Product[];
  totalField: number;
  repartidores = [];
  albaranes: any;
  client;
  gestionCard: any;
  gestionFakeCard: any;

  dragObject: any;

  newRepartidor: any;
  newCard: any;
  viewIcon = false;
  newIndex: any;
  previousCard: any;
  nextIndex: any;
  centralAvisos = false;
  fakeRepartidor: any;
  reloadCoordinates = false;

  generacionView = false;
  controlView = false;
  mapaView = false;
  note = false;
  clientDate: any;
  alertErrorMessage = false;


  constructor(
    private utils: UtilsService,
    private rest: RestService,
    private alert: AlertController
  ) {}


  loadCoordinates() {
    setTimeout(async () => {
      if (this.mapaView) {
        this.clearOverlays();
        await this.getRepartidores();
        this.reloadCoordinates = true;
        await this.loadMapWithCoordinates();
        await this.viewClientsInMapOnInit();
      }
      this.loadCoordinates();
    }, 30000);
  }

  clearOverlays() {
    this.markers.forEach(marker => {
      marker.setMap(null);
    })
    this.markers.length = 0;
  }

  addNote() {
    if (this.note === false) {
      this.note = true;
    } else {
      this.note = false;
      this.extraNotesField = '';
    }
  }
  async loadMapWithCoordinates() {
    if (this.mapaView) {
      try {
        if (!this.reloadCoordinates) {
          await this.utils.showLoading();
        }
        const resp: any = await this.rest.getOpenSessions();
        console.log(resp);
        if (resp) {
          this.coordinates = resp.body;
        }
        // this.coordinates.forEach(
        //   (coords, index) => {
        //     const aux = new google.maps.LatLng(coords.lat, coords.long);
        //     this.coordinates[index] = aux;
        //   }
        // );
        const allPos = this.coordinates.filter(elem => elem.lastPosition);
        let center: LatLng = new google.maps.LatLng(40.416664, -3.703989);
        if (allPos && allPos.length > 0) {
          center = new google.maps.LatLng(allPos[0].lastPosition.latitude, allPos[0].lastPosition.longitude);
        }

        // We create the map
        if (!this.reloadCoordinates) {
          const mapOptions = {
            center,
            zoom: 13,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true
          };
          this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
        }

        // // TODO: No detecta este evento
        // this.map.on(GoogleMapsEvent.MAP_CLICK).subscribe((point) => {
        //   this.map.setZoom(11);
        //   this.map.setCenter(point.latLng);
        //   // this.map.setCenter(.getPosition());
        // });

        // This event listener calls addMarker() when the map is clicked.
        google.maps.event.addListener(this.map, 'click', (event) => {
          this.map.setZoom(16);
          this.map.setCenter(event.latLng);
        });
        this.coordinates.forEach(
          async (coords, index) => {
            if (coords.lastPosition) {
              const marker = new google.maps.Marker({
                repartidor: coords.user._id,
                map: this.map,
                position: {
                  lat: coords.lastPosition.latitude,
                  lng: coords.lastPosition.longitude
                },
                icon: {
                  url: this.getIcon('In Progress', coords)
                },
                style: [
                  {
                    featureType: 'poi',
                    stylers: [
                      {
                        visibility: 'off'
                      }
                    ]
                  }
                ]
              });
              this.markers.push(marker);

              if (coords.user) {
                const resp3: any = await this.rest.getFurgonetaCargaByUserId(coords.user._id);
                const userCarga = [];

                // tslint:disable-next-line: prefer-for-of
                if (resp3.body.summary) {
                  for (let i = 0; i < Object.keys(resp3.body.summary).length; i++) {
                    const productId = Object.keys(resp3.body.summary)[i];
                    const productValue = Object.values(resp3.body.summary)[i];
                    // const resp2: any = await this.rest.getProduct(productId);
                    if (productValue > 0) {
                      userCarga.push(`<br>${ProductById(productId)}: ${productValue}<br>`);
                    }
                  }
                }
                const infowindow = new google.maps.InfoWindow({
                  content: `<h4>${coords.user.firstName + ' ' + coords.user.lastName}</h4>  <br> Nº de cargas: ${coords.user.cargas.length} <br> Zonas:
                                  ${coords.user.zonas || 'Ninguna'} ${userCarga.toString()}`
                });

                google.maps.event.addListener(marker, 'click', () => {
                  this.map.setCenter(marker.getPosition());
                  this.map.setZoom(16);
                  infowindow.open(this.map, marker);
                });
              }
            }
          }
        );
        if (!this.reloadCoordinates) {
          await this.utils.hideLoading();
        }
      } catch (error) {
        console.log(error);
        // await this.utils.showToast('Error al cargar posición de repartidores', 'danger', 3000);
        this.alertErrorMessage = true;
      }
    }
  }

  // getIcon(status: string) {
  //   if (this.markerCounter > 9 || this.markerCounter === this.coordinates.length) {
  //     this.markerCounter = 0;
  //   }
  //   this.markerCounter = this.markerCounter + 1;
  //   const marker = '../../../assets/icons/control/' + this.markerCounter + '.png';

  //   switch (status) {
  //     case 'Completed': {
  //       return marker;
  //     }
  //     case 'In Progress': {
  //       return marker;
  //     }
  //     default: {
  //       return marker;
  //     }
  //   }
  // }

  getIcon(status: string, coords: any) {
    const marker = '../../../assets/icons/control/' + coords.user.color + '.png';
    switch (status) {
      case 'Completed': {
        return marker;
      }
      case 'In Progress': {
        return marker;
      }
      default: {
        return marker;
      }
    }
  }

  onMapClick(e) {
    console.log(e);
    this.map.setCameraZoom(15);
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log(event);
    this.previousCard = event.previousContainer.data[event.previousIndex];
    this.newIndex = event.currentIndex;
    this.newCard = event.previousContainer.data[0];
    this.newRepartidor = event.container.connectedTo;
    if (((event.previousContainer === event.container) && (event.previousIndex !== event.currentIndex)) ||
      (event.previousContainer !== event.container) && (event.previousIndex === event.currentIndex) ||
      (event.previousContainer !== event.container) && (event.previousIndex !== event.currentIndex)) {
      this.editAlbaran();
    }
  }

  dropByModal(ev: any) {
    this.newIndex = parseInt(ev.orderIndex, 10);
    this.previousCard = ev.oldCard
    this.newRepartidor = ev.empleado;
    this.newCard = ev.newCard
    this.editAlbaran();
  }

  ngOnInit() {
    this.loadCoordinates();
  }

  async ionViewWillEnter() {
    // this.utils.showLoading();
    await this.getRepartidores();
    await this.getProducts();
    await this.getAlbaranes();
    await this.getFakeRepartidor();
    if (this.mapaView) {
      await this.loadMapWithCoordinates();
      await this.viewClientsInMapOnInit();
    }
  }

  async getFakeRepartidor() {
    const result: any = await this.rest.getUser('5f0c2b821d2f0703ebf97e46');
    this.fakeRepartidor = result.body;
    console.log(this.fakeRepartidor);
    const temp = {};
    const data: any = await this.rest.getAlbaranesByUserIdWithFilters(this.fakeRepartidor._id, false, false, false, false, true);
    console.log(data);
    if (data.body.length > 0) {
      data.body = data.body.sort((a, b) => a.orderIndex - b.orderIndex);
      for (const body of data.body) {
        const newCard = {
          repartidorInfo: this.fakeRepartidor,
          clienteInfo: body
        }
        if (temp[this.fakeRepartidor._id]) {
          temp[this.fakeRepartidor._id].push(newCard);
        } else {
          temp[this.fakeRepartidor._id] = [newCard];
        }
        // this.gestionCard[this.fakeRepartidor._id] = [...temp[this.fakeRepartidor._id]];
        // console.log(temp[this.fakeRepartidor._id]);
        // console.log(temp);
      }
    }
    this.gestionFakeCard = temp;
  }

          //////////////////////////////////////////////////////////////////////////////////////////////////////////////

          getShortProductName(prodName: string): string {
            switch (prodName) {
              case 'CUBITO VOGT':
                return 'CV';
              case 'CUBITO SCOTSMAN':
                return 'CS';
              case 'HIELO PILEE':
                return 'HP';
              case 'ESCAMA RED':
                return 'ER';
              case 'ESCAMA CUAD':
                return 'EC';
              case 'HIELO CUADRADO':
                return 'HC';
              case 'BARRA':
                return 'BA';
              case 'BLOQUES':
                return 'BL';
              case 'HIELO SECO':
                return 'HS';
              case 'DESPLAZAMIENTO':
                return 'D';
              case 'ALQUILER CAMARAS':
                return 'AC';
              case 'HIELO ESPECIAL':
                return 'HE';
              case 'CUBO 5X5':
                return 'CB5X5' ;
              case 'LINGOTE 4X12':
                return 'LN4X12';
              case 'ESFERAS 6CM':
                return 'ES6' ;
              case 'CUBO 5,5 X 5,5':
                return 'CB5,5' ;
              case 'CUBO 5X7':
                return 'CB5X7' ;
              case 'LINGOTE 3X15':
                return 'LN3X15';
              case 'ESFERAS 3CM':
                return 'ES3';
            

              default:
                return prodName; // Por defecto, devuelve el nombre completo
            }
          }
          
          

          openCustomQuantityInput(productName: string) {
            const customQuantity = prompt(`Ingresa una cantidad personalizada para ${productName}:`);
            if (customQuantity !== null) {
              const parsedQuantity = parseInt(customQuantity, 10);
              if (!isNaN(parsedQuantity)) {
                this.fields[productName] = parsedQuantity;
              }
            }
          }

          addToQuantity(productName: string, amount: number) {
            if (!this.fields[productName]) {
              this.fields[productName] = amount;
            } else {
              this.fields[productName] = parseInt(this.fields[productName]) + amount;
            }
          }
          

          decrementQuantity(productName: string) {
            if (this.fields[productName] && this.fields[productName] > 0) {
              this.fields[productName]--;
            }
          }
          
          incrementQuantity(productName: string) {
            if (!parseInt(this.fields[productName])) {
              this.fields[productName] = 1;
            } else {
              this.fields[productName];
            }
          }

          parseIntOrZero(value: string): number {
            const intValue = parseInt(value, 10);
            return isNaN(intValue) ? 0 : intValue;
          }
          

          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  async saveGestion() {
    await this.utils.showLoading();
    try {
      this.productsClient.forEach(
        product => {
          console.log(this.fields);
          console.log(this.fields[product.name]);
          if (!this.fields[product.name]) {
            this.fields[product.name] = 0;
          }
          product.unit = parseInt(this.fields[product.name].toString(), 10);
        });
        
        console.log(this.productsClient, "PRODUCTSCLIENTS");

      if (this.gestionCard[Object.keys(this.gestionCard)[0]]) {
        Object.keys(this.gestionCard).forEach(
          key => {
            console.log(key);
            console.log(this.repartidorField);
            if (this.repartidorField === key) {
              this.nextIndex = this.gestionCard[Object.keys(this.gestionCard)[0]].length;
            }
          }
        )
      } else {
        this.nextIndex = 0;
      }
      const sendAlbaran: Albaran = {
        orderIndex: this.nextIndex ? this.nextIndex : 0,
        cliente: this.clientSelected._id,
        comment: this.extraNotesField,
        location: {
          latitude: null,
          longitude: null
        },
        total: 0,
        totalWithIva: 0,
        regalos: [],
        empleado: this.repartidorField,
        cantidad: [...this.productsClient],
        fecha: null
      };
      if (sendAlbaran.cantidad.length > 0) {
        sendAlbaran.cantidad.forEach(
          (c, index, array) => {
            console.log(c);
            if (!c.unit) {
              c.unit = 0;
            }
            c.unit = parseInt(c.unit.toString(), 10);
            sendAlbaran.total += (c.unit ? c.unit * c.price : 0);
            sendAlbaran.totalWithIva += (c.unit ? ((c.price * c.unit) * ((100 + c.iva) / 100)) : 0);
            // tslint:disable-next-line: deprecation
            if (isNullOrUndefined(c.iva)) {
              c.iva = 0;
            }
          }
        );
      }
      let invalid = false;
      await this.getProducts();
      this.fillUserCargas();
      if (this.repartidorField !== this.fakeRepartidor._id) {
        await this.fillPromocion(sendAlbaran);
        await this.fillUserAlbaranes();
        sendAlbaran.cantidad.forEach(
          prod => {
            const p: any = this.userCargas[prod.product];
            if (!p) {
              this.userCargas[prod.product] = 0;
            }
            if (!this.userAlbs) {
              this.userAlbs = {};
            }
            let regalo: any = sendAlbaran.regalos.filter(elem => elem.product === prod.product);
            if (regalo && regalo.length > 0) {
              regalo = regalo[0].unit;
            } else {
              regalo = 0;
            }
            if ((((this.userCargas[prod.product] -
              (this.userAlbs[prod.product] ? this.userAlbs[prod.product] : 0) - regalo) < prod.unit) && prod.unit > 0
              && (this.userCargas[prod.product] -
                (this.userAlbs[prod.product] ? this.userAlbs[prod.product] : 0) - regalo) >= 0)
              && (prod.product !== '5ef5e43bdd4795f0c32a1530' && prod.product !== '5ef5e43bdd4795f0c32a152f')) {
              this.utils.showToast('El número de unidades es mayor al que dispone el repartidor. Ten en cuenta las promociones.',
                'danger', 10000);
              invalid = true;
            }
          }
        );
      }
      if (!invalid) {
        sendAlbaran.regalos = [];
        console.log(sendAlbaran);
        const result = await this.rest.createAlbaran(sendAlbaran);
        console.log(result);
        if (result) {
          await this.utils.hideLoading();
          const id = result.body.albaran._id;
          await this.getRepartidores();
          await this.getFakeRepartidor();
          await this.viewClientsInMapOnInit()
          await this.getProducts();
          await this.getAlbaranes();
          this.resetForm();
          this.searchClient = '';
          // this.repartidorField = '';
          this.repartidorField = '5f0c2b821d2f0703ebf97e46';
          /*setTimeout(
            () => location.reload()
            , 300);*/
        } else {
          await this.utils.hideLoading();
          await this.utils.showToast('Hubo un error al intentar crear albarán', 'danger', 3000);
        }
      } else {
        await this.utils.hideLoading();
      }
    } catch (error) {
      console.log(error);
      await this.utils.hideLoading();
      await this.utils.showToast('Hubo un error al intentar crear albarán', 'danger', 3000);
    }
  }

  async getGestiones() {
    await this.utils.showLoading();
    const result: any = await this.rest.getGestiones(1, 10);
    // this.lines = result.body.records;
    await this.utils.hideLoading();
  }
  async getGestion(gestionId) {
    await this.utils.showLoading();
    const result: any = await this.rest.getGestion(gestionId);
    // this.lines = result.body.records;
    await this.utils.hideLoading();
  }

  async getProducts() {
    const resp: any = await this.rest.getProducts(1, 1000);
    if (resp.body && resp.body.records) {
      this.products = resp.body.records;
      console.log(this.products);
      this.products.forEach(
        prod => {
          this.fields[prod.name] = '0'
        }
      );
    }
  }

  clearData() {
    this.searchClient = '';
    this.repartidorField = '';
    this.products.forEach(
      prod => {
        this.fields[prod.name] = '0'
      }
    );
    this.extraNotesField = '';
  }

  async changeClientInput(event) {
    console.log('show LOAD');
    await this.utils.showLoading('Cargando...', 100);
    try {
      console.log(event);
      this.searchClient = event;
      this.clientChanged = true;
      if (this.searchClient !== '') {
        const resp = await this.rest.getClientsByName(this.searchClient, true);
        if (resp.body) {
          this.clients = resp.body.records;
          console.log(this.clients);
        }
      } else {
        this.productsClient = [];
        this.tarifasClient = [];
      }
      await this.utils.hideLoading();
    } catch (error) {
      console.log(error);
      await this.utils.hideLoading();
    }
    this.myInput.setFocus();
  }

  async selectClient(client: any) {
    this.clientField = client._id;
    this.searchClient = client.nombre;
    this.clientSelected = client;
    this.clientChanged = false;
    try {
      await this.utils.showLoading();
      const tarifasDefault = await this.getDefaultTarifas();
      const lastDate: any = await this.rest.getLastAlbaranDateByClientId(this.clientField);
      console.log(lastDate);

      if (lastDate && lastDate.body) {
        this.clientDate = lastDate.body.result;
      }
      console.log(tarifasDefault);
      this.tarifasClient = client.tarifa || [];

      this.products.forEach(
        prod => {
          const aux = this.tarifasClient.filter(elem => elem.product === prod.name);
          if (!aux || aux.length === 0) {
            console.log(prod);
            console.log(this.tarifasClient);
            this.tarifasClient[prod.name] = tarifasDefault.filter(t => t.product === prod.name);
            this.tarifasClient[prod.name] = this.tarifasClient[prod.name][0];
          }
        }
      );

      // We store both the name and id of the products
      this.productsClient = [...this.products];
      this.productsClient.forEach(
        product => {
          const auxId = product._id;
          delete product._id;
          product.product = auxId;
          product.unit = 0;
          let p = this.tarifasClient.filter(e => e.product === product.name);
          if (p.length === 0) {
            p = this.tarifasClient[product.name];
          }
          if (p.length > 0) {
            product.price = p[0].price;
            product.iva = p[0].iva;
          } else {
            product.price = p.price;
            product.iva = p.iva;
          }
        }
      );
      await this.utils.hideLoading();
    } catch (error) {
      console.log(error);
      await this.utils.hideLoading();
    }
  }

  async fillPromocion(albaran: any) {
    let regalo: any;
    const resp: any = await this.rest.getClient(this.clientField);
    if (resp) {
      console.log(resp.body);
      if (!this.clientSelected) {
        this.clientSelected = resp.body
      }
      (this.clientSelected.promocion as any) = resp.body.promocion;
    }
    if (this.clientSelected.promocion && this.clientSelected.promocion.length > 0) {
      console.log(this.productsClient);
      this.productsClient.forEach(
        elem => {
          console.log(this.clientSelected.promocion);
          console.log(elem.product);
          let prod: any = this.clientSelected.promocion.filter(e => ProductByNAME(e.product) === elem.product);
          if (prod && prod.length > 0) {
            prod = prod[0];
            console.log(prod);
            regalo = (elem.unit / prod.venta) * parseInt(prod.regalo, 10);

            regalo = Math.trunc(parseInt(regalo, 10));
            if (regalo > 0) {
              if (!albaran.regalos) {
                albaran.regalos = [];
              }
              albaran.regalos.push({
                product: elem.product,
                unit: regalo
              });
            }
          }
        }
      );
    }
  }

  async getDefaultTarifas() {
    let res = [];
    const resp: any = await this.rest.getDefaultTarifas();
    if (resp) {
      res = resp.body.records;
      console.log(res);
    }
    return res;
  }

  changeTotal() {
    this.totalField = 0;
    if (this.productsClient) {
      this.productsClient.forEach(
        elem => {
          console.log(elem);
          this.totalField += (elem.unit * elem.price) || 0;
        }
      );
    }
  }

  async getRepartidores() {
    const temp = {};
    this.repartidores = [];


    const result: any = await this.rest.getUsersWithCargas();

    /* const result: any = await this.rest.getCurrentCargas();
    console.log(result2);
    if (result !== false) {
      const cargas = result.body.records;
      this.repartidores = cargas.map(elem => elem.user);
      this.repartidores = this.utils.removeDuplicatesArray(this.repartidores);
      console.log(this.repartidores);
     // this.repartidores = this.repartidores.filter((el) => el.role === 'repartidor');

    }*/


    for (const repartidor of result.body.records) {
      if (repartidor.cargas.length > 0) {
        this.repartidores.push(repartidor);
        const data: any = await this.rest.getAlbaranesByUserIdWithFilters(repartidor._id, false, false, false, false, true);
        if (data.body.length > 0) {
          data.body = data.body.sort((a, b) => a.orderIndex - b.orderIndex);
          for (const body of data.body) {
            const newCard = {
              repartidorInfo: repartidor,
              clienteInfo: body
            }
            if (temp[repartidor._id]) {
              temp[repartidor._id].push(newCard);
            } else {
              temp[repartidor._id] = [newCard];
            }
            console.log(temp);
            // this.client = body;
            // console.log(this.client)
            // if (repartidor._id === body.empleado) {
            //   this.repartidores.push(repartidor);
            //   const newCard = {
            //     repartidorInfo: repartidor,
            //     clienteInfo: body
            //   }
            //   this.gestionCard.push(newCard);
            // }
          }
        }
      }
    }
    this.gestionCard = temp;
  }
  resetForm() {
    this.products.forEach(
      prod => {
        this.fields[prod.name] = 0
      }
    );
  }

  parseProduct(prodId: string) {
    const prod = ProductById(prodId);
    return prod ? prod.charAt(0) : '';
  }

  checkButtonStatus() {
    if (this.repartidorField === '' || this.repartidorField === undefined) {
      return true;
    }
    let sum = 0;
    Object.keys(this.fields).forEach(
      f => {
        sum += parseInt(this.fields[f], 10);
      }
    );
    if (sum < 1) {
      return true;
    }
    if (this.searchClient === '' || this.searchClient === undefined) {
      return true;
    }
    for (const fieldValue of Object.values(this.fields)) {
      if (fieldValue < 0) {
        return true
      }
    }
    const total = [];
    Object.values(this.fields).forEach(field => {
      total.push(field);
    })
    const totalResult = parseInt(total.reduce((a, b) => a + b), 10);
    if (isNaN(totalResult) || totalResult < 1) {
      return true;
    }
  }

  async fillUserAlbaranes() {
    try {
      const respAlbs: any = await this.rest.getAlbaranesCurrentSessionGrouped();
      if (respAlbs && respAlbs.body) {
        this.userAlbs = respAlbs.body;
        let repartidor: any = this.repartidores.filter(elem => elem._id === this.repartidorField);
        if (repartidor && repartidor.length > 0) {
          repartidor = repartidor[0];
          this.userAlbs = this.userAlbs[repartidor.username];
          if (this.userAlbs && this.userAlbs.length > 0) {
            this.userAlbs = this.userAlbs.map(alb => alb.cantidad);
            this.userAlbs = this.utils.groupByUnitsAndProductsLong2(this.userAlbs.flat(), 'product');
            if (this.userAlbs && Object.keys(this.userAlbs).length > 0) {
              Object.keys(this.userAlbs).forEach(
                key => {
                  this.userAlbs[key] = this.userAlbs[key].map(elem => elem.units);
                  this.userAlbs[key] = this.userAlbs[key].reduce((a, b) => a + b, 0);
                }
              );
            }
          }
          console.log(this.userAlbs);
        }
      }
    } catch (error) {
      console.log(error);
      await this.utils.showToast('Error obteniendo albaranes del usuario', 'danger', 3000);
    }
  }

  fillUserCargas() {
    if (this.repartidorField === this.fakeRepartidor._id) {
      const repartidor = this.fakeRepartidor;
      this.userCargas = repartidor.cargas.map(e => e.product);
      this.userCargas = this.utils.groupByUnitsAndProducts(this.userCargas.flat(), '_id');
      if (this.userCargas && Object.keys(this.userCargas).length > 0) {
        Object.keys(this.userCargas).forEach(
          key => {
            this.userCargas[key] = this.userCargas[key].reduce((a, b) => a + b, 0);
          }
        );
      }
    }

    if (this.repartidorField && this.repartidorField !== this.fakeRepartidor._id) {

      const repartidor = this.repartidores.filter(user => user._id === this.repartidorField);
      if (repartidor[0].cargas.length > 0) {
        const openCargas = repartidor[0].cargas.filter(elem => elem.closed === false);
        this.userCargas = openCargas.map(e => e.product);
      } else {
        this.userCargas = repartidor[0].cargas;
      }

      this.userCargas = this.utils.groupByUnitsAndProducts(this.userCargas.flat(), '_id');
      if (this.userCargas && Object.keys(this.userCargas).length > 0) {
        Object.keys(this.userCargas).forEach(
          key => {
            this.userCargas[key] = this.userCargas[key].reduce((a, b) => a + b, 0);
          }
        );
      }
    }
  }

  async editAlbaran() {
    await this.utils.showLoading();
    const sendAlbaran: Albaran = {
      orderIndex: this.newIndex,
      _id: this.previousCard.clienteInfo._id,
      empleado: this.newRepartidor
    };

    this.repartidorField = this.newRepartidor;
    this.clientField = this.newCard.clienteInfo.cliente._id;
    this.productsClient = this.newCard.clienteInfo.cantidad;
    let invalid = false;
    if (this.repartidorField !== this.fakeRepartidor._id) {
      this.fillUserCargas();
      await this.fillPromocion(sendAlbaran);
    }
    sendAlbaran.cantidad = this.previousCard.clienteInfo.cantidad;
    if (this.repartidorField !== this.fakeRepartidor._id) {
      sendAlbaran.cantidad.forEach(
        prod => {
          const p: any = this.userCargas[prod.product];
          if (!p) {
            this.userCargas[prod.product] = 0;
          }
          if (!this.userAlbs) {
            this.userAlbs = {};
          }
          if (sendAlbaran.regalos && sendAlbaran.regalos.length > 0) {
            let regalo: any = sendAlbaran.regalos.filter(elem => elem.product === prod.product);
            if (regalo && regalo.length > 0) {
              regalo = regalo[0].unit;
            } else {
              regalo = 0;
            }
            if ((((this.userCargas[prod.product] -
              (this.userAlbs[prod.product] ? this.userAlbs[prod.product] : 0) - regalo) < prod.unit) && prod.unit > 0
              && (this.userCargas[prod.product] -
                (this.userAlbs[prod.product] ? this.userAlbs[prod.product] : 0) - regalo) >= 0)
              && (prod.product !== '5ef5e43bdd4795f0c32a1530' && prod.product !== '5ef5e43bdd4795f0c32a152f')) {
              this.utils.showToast('El número de unidades es mayor al que dispone el repartidor. Ten en cuenta las promociones.',
                'danger', 3000);
              invalid = true;
            }
          } else {
            if ((((this.userCargas[prod.product] -
              (this.userAlbs[prod.product] ? this.userAlbs[prod.product] : 0)) < prod.unit) && prod.unit > 0
              && (this.userCargas[prod.product] -
                (this.userAlbs[prod.product] ? this.userAlbs[prod.product] : 0)) >= 0)
              && (prod.product !== '5ef5e43bdd4795f0c32a1530' && prod.product !== '5ef5e43bdd4795f0c32a152f')) {
              this.utils.showToast('El número de unidades es mayor al que dispone el repartidor. Ten en cuenta las promociones.',
                'danger', 3000);
              invalid = true;
            }
          }
        }
      );
    }
    if (!invalid) {
      sendAlbaran.regalos = [];
      const result = await this.rest.editAlbaran(sendAlbaran);
      this.repartidorField = '';
      await this.utils.hideLoading();
      await this.utils.showToast('Editado con exito', 'success', 3000);
      console.log(result);
      await this.getRepartidores();
      await this.getFakeRepartidor();
      await this.getProducts();
      await this.getAlbaranes();
      await this.loadMapWithCoordinates();
      await this.viewClientsInMapOnInit();
      if (!result) {
        await this.utils.hideLoading();
        await this.utils.showToast('Hubo un error al intentar crear albarán', 'danger', 3000);
      }
    } else {
      await this.utils.hideLoading();
      console.log('repartidor sin sufuciente carga')
    }
  }

  async getAlbaranes() {
    const result: any = await this.rest.getAlbaranes();
    console.log(result.body.records);
    this.albaranes = result.body.records;
    this.albaranes.forEach(albaran => {
      if (albaran.empleado.firstName === 'usuarioFake') {
        albaran.empleado.firstName = 'Albaran sin asignar a ningún repartidor',
          albaran.empleado.lastName = ''
      }
    });
  }

  async deleteAlbaran(card: any) {
    const alert = await this.alert.create({
      header: '¿Borrar?',
      message: 'Borrar albaran ' + card.clienteInfo.id,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Sí',
          handler: async () => {
            await this.utils.showLoading();
            console.log(card);
            const albaranId = card.clienteInfo._id;
            const result: any = await this.rest.deleteAlbaran(albaranId);
            if (result) {
              await this.utils.hideLoading();
              await this.utils.showToast('Borrado con exito', 'success', 3000);
              await this.getRepartidores();
              await this.getFakeRepartidor();
              await this.getProducts();
              await this.getAlbaranes();
              await this.loadMapWithCoordinates();
              await this.viewClientsInMapOnInit();
            } else {
              await this.utils.hideLoading();
              await this.utils.showToast('Hubo un error al intentar borrar albarán', 'danger', 3000);
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async repeatAlbaran(albaran) {
    console.log(this.products);
    this.searchClient = albaran.cliente.nombre;
    this.repartidorField = albaran.empleado._id;
    this.selectClient(albaran.cliente);
    this.products = [];
    await this.getProducts();

    albaran.cantidad.forEach(element => {
      for (const product of this.products) {
        if (element.product === product._id) {
          this.fields[product.name] = element.unit;
        }
      }
    });
  }

  viewCentralAvisos() {
    if (this.centralAvisos === false) {
      this.centralAvisos = true
    } else {
      this.centralAvisos = false
    }
  }

  async clientCoordinatesInMap(card: any, event) {
    if (card.clienteInfo.cliente.dfiscal || card.clienteInfo.cliente.dfiscal !== '') {
      if (!card.clienteInfo.cliente.location) {
        const result = await this.getAddressWithGoogle(card);
        if (result && ((result as any).length > 0)) {
          const sendClient: Client = {
            _id: card.clienteInfo.cliente._id,
            location: {
              latitude: (result[0].geometry.location.lat()),
              longitude: (result[0].geometry.location.lng())
            }
          }
          try {
            this.rest.editClient(sendClient);
            card.clienteInfo.cliente.location = {
              latitude: (result[0].geometry.location.lat()),
              longitude: (result[0].geometry.location.lng())
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          await this.utils.showToast('No ha sido posible encontrar la dirección del cliente: '
            + card.clienteInfo.cliente.nombre, 'danger', 3000);
        }
      }
    }

    if (card.clienteInfo.cliente.location) {
      const center: LatLng = new google.maps.LatLng(card.clienteInfo.cliente.location.latitude,
        card.clienteInfo.cliente.location.longitude);
      //  this.map.setZoom(11);
      // this.map.panTo(center);
      if (event) {
        this.map.panTo(center);
      }

      const marker = new google.maps.Marker({
        position: center,
        map: this.map
      });
      // this.markers.push(marker);
      const infowindow = new google.maps.InfoWindow({
        content: `<h4>${card.clienteInfo.cliente.nombre}</h4>  <br> ${card.clienteInfo.cliente.dfiscal}`
      });
      google.maps.event.addListener(marker, 'click', () => {
        this.map.setCenter(marker.getPosition());
        this.map.setZoom(16);
        infowindow.open(this.map, marker);
      });
    }
  }

  async getAddressWithGoogle(card: any) {
    return new Promise(
      resolve => {
        try {
          const geocoder = new google.maps.Geocoder();
          geocoder.geocode({
            address: card.clienteInfo.cliente.dfiscal
          }, (result, status) => {
            console.log(result);
            console.log(status);
            resolve(result);
          });
        } catch (error) {
          console.log(error);
        }
      }
    )
  }

  async viewClientsInMapOnInit() {
    if (Object.values(this.gestionCard).length > 0) {
      Object.values(this.gestionCard).forEach(element => {
        (element as any).forEach(card => {
          setTimeout(
            () => this.clientCoordinatesInMap(card, false)
            , 300);
        });
      });
    }

    if (this.gestionFakeCard) {
      if (Object.values(this.gestionFakeCard).length > 0) {
        Object.values(this.gestionFakeCard).forEach(element => {
          (element as any).forEach(card => {
            setTimeout(
              () => this.clientCoordinatesInMap(card, false)
              , 300);
          });
        });
      }
      for (const cards of Object.values(this.gestionCard)) {
        (cards as any).forEach(card => {
          if (card.clienteInfo.cliente.location) {
            const center: LatLng = new google.maps.LatLng(card.clienteInfo.cliente.location.latitude,
              card.clienteInfo.cliente.location.longitude);
            // this.map.setZoom(11);
            // this.map.panTo(center);
            const marker = new google.maps.Marker({
              position: center,
              map: this.map
            });
            const infowindow = new google.maps.InfoWindow({
              content: `<h4>${card.clienteInfo.cliente.nombre}</h4>  <br> ${card.clienteInfo.cliente.dfiscal}`
            });
            google.maps.event.addListener(marker, 'click', () => {
              this.map.setCenter(marker.getPosition());
              this.map.setZoom(16);
              infowindow.open(this.map, marker);
            });
          }
        });
      }

      for (const cards of Object.values(this.gestionFakeCard)) {
        (cards as any).forEach(card => {
          if (card.clienteInfo.cliente.location) {
            const center: LatLng = new google.maps.LatLng(card.clienteInfo.cliente.location.latitude,
              card.clienteInfo.cliente.location.longitude);
            // this.map.setZoom(11);
            // this.map.panTo(center);
            const marker = new google.maps.Marker({
              position: center,
              map: this.map
            });
            const infowindow = new google.maps.InfoWindow({
              content: `<h4>${card.clienteInfo.cliente.nombre}</h4>  <br> ${card.clienteInfo.cliente.dfiscal}`
            });
            google.maps.event.addListener(marker, 'click', () => {
              this.map.setCenter(marker.getPosition());
              this.map.setZoom(16);
              infowindow.open(this.map, marker);
            });
          }
        });
      }
    }
  }
  async refreshPage() {
    this.reloadCoordinates = false;
    await this.getRepartidores();
    await this.getProducts();
    await this.getAlbaranes();
    await this.getFakeRepartidor();
    if (this.mapaView) {
      await this.loadMapWithCoordinates();
      await this.viewClientsInMapOnInit();
    }

    this.products.forEach(
      prod => {
        this.fields[prod.name] = 0
      }
    );
  }

  async openPageView(data: string) {
    if (data === 'generador') {
      if (this.generacionView) {
        this.generacionView = false;
      } else {
        this.generacionView = true;
      }
    }
    else if (data === 'control') {
      if (this.controlView) {
        this.controlView = false;
      } else {
        this.controlView = true;
        await this.refreshPage();
      }
    }
    else if (data === 'mapa') {
      if (this.mapaView) {
        this.mapaView = false;
      } else {
        this.mapaView = true;
        await this.refreshPage();
      }
    }
  }

  async clientInfo(info) {
    const alert = await this.alert.create({
      subHeader: 'Información de cliente',
      message: '<p><b>Albarán: </b>' +
        info.id + '<p><b>Nombre: </b>' +
        info.cliente.nombre + '</p><p><b>Dirección:</b> ' +
        info.cliente.dfiscal + '</p>',
      buttons: ['OK']
    });

    await alert.present();
  }



  async cargaRepartidoInfo(repartidor) {
    const cargas = repartidor.cargas;
    const repartidorCargas = [];
    // console.log(cargas);

    cargas.forEach(carga => {
      carga.product.forEach(prod => {
        const result = this.parseProduct(prod._id);
        const resultado = '<b> ' + result + '</b>' + ': ' + prod.unit + ' ';
        if (prod.unit > 0) {
          repartidorCargas.push(resultado);
        }
      });
    });
    console.log(repartidorCargas);

    const alert = await this.alert.create({
      cssClass: 'my-custom-class',
      header: 'Carga actual del repartidor',
      message: repartidorCargas.toString(),
      buttons: ['OK']
    });

    await alert.present();
  }

  async viewOn(ev: any) {
    this.generacionView = false;
    this.controlView = false;
    this.mapaView = false;
    this[ev] = true;
    if (this.mapaView) {
      await this.utils.showLoading();
      await this.refreshPage();
      await this.utils.hideLoading();
    }
  }
}