import { ProductType } from '../interfaces/product';

export function ProductById(id): ProductType {
  switch (id) {
    case '5eb9c9e3d0ca13a4332680da':
      return 'ESCAMA RED';
    case '5eb9c9e3d0ca13a4332680d9':
      return 'HIELO PILEE';
    case '5eb9c9e3d0ca13a4332680dc':
      return 'CUBITO VOGT';
    case '5ea75b95ece96f572f598255':
      return 'HIELO CUADRADO';
    case '5eb9c9e3d0ca13a4332680db':
      return 'CUBITO SCOTSMAN';
    case '5ef5e43bdd4795f0c32a152b':
      return 'ESCAMA CUAD';
    case '5ef5e43bdd4795f0c32a152c':
      return 'BARRA';
    case '5ef5e43bdd4795f0c32a152d':
      return 'BLOQUES';
    case '5ef5e43bdd4795f0c32a152e':
      return 'HIELO SECO';
    case '5ef5e43bdd4795f0c32a152f':
      return 'DESPLAZAMIENTO';
    case '5ef5e43bdd4795f0c32a1530':
      return 'ALQUILER CAMARAS';
    case '5ef5e43bdd4795f0c32a1531':
      return 'HIELO ESPECIAL';
    case '6509a1e01bb0978fd8b5b15f':
      return 'CUBO 5X5';
    case '6509a4241bb0978fd8b5b161':
      return 'LINGOTE 4X12';
    case '650dbb3eb78327095c20a226':
      return 'ESFERAS 6CM';  
    case '650aae4834900b974d3fba94':
      return 'CUBO 5,5 X 5,5';
    case '650aae7934900b974d3fba95':
      return 'CUBO 5X7';
    case '650aae9a34900b974d3fba96':
      return 'LINGOTE 3X15';
    case '650aaebe34900b974d3fba97':
      return 'ESFERAS 3CM';  
  }
}

export function GetIdByIndex(index) {
  const products = [
    '5eb9c9e3d0ca13a4332680dc',
    '5eb9c9e3d0ca13a4332680db',
    '5eb9c9e3d0ca13a4332680d9',
    '5eb9c9e3d0ca13a4332680da',
    '5ef5e43bdd4795f0c32a152b',
    '5ea75b95ece96f572f598255',
    '5ef5e43bdd4795f0c32a152c',
    '5ef5e43bdd4795f0c32a152d',
    '5ef5e43bdd4795f0c32a152e',
    '5ef5e43bdd4795f0c32a152f',
    '5ef5e43bdd4795f0c32a1530',
    '5ef5e43bdd4795f0c32a1531',
    '6509a1e01bb0978fd8b5b15f',
    '6509a4241bb0978fd8b5b161',
    '650dbb3eb78327095c20a226',
    '650aae4834900b974d3fba94',
    '650aae7934900b974d3fba95',
    '650aae9a34900b974d3fba96',
    '650aaebe34900b974d3fba97'
  ]
  return products[index];
}

export function ProductByNAME(name: string) {
  name = name.toUpperCase();
  switch (name) {
    case 'CUBITO VOGT':
      return '5eb9c9e3d0ca13a4332680dc';
    case 'CUBITO SCOTSMAN':
      return '5eb9c9e3d0ca13a4332680db';
    case 'HIELO PILEE':
      return '5eb9c9e3d0ca13a4332680d9';
    case 'ESCAMA RED':
      return '5eb9c9e3d0ca13a4332680da';
    case 'ESCAMA CUAD':
      return '5ef5e43bdd4795f0c32a152b';
    case 'HIELO CUADRADO':
      return '5ea75b95ece96f572f598255';
    case 'BARRA':
      return '5ef5e43bdd4795f0c32a152c';
    case 'BLOQUES':
      return '5ef5e43bdd4795f0c32a152d';
    case 'HIELO SECO':
      return '5ef5e43bdd4795f0c32a152e';
    case 'DESPLAZAMIENTO':
      return '5ef5e43bdd4795f0c32a152f';
    case 'ALQUILER CAMARAS':
      return '5ef5e43bdd4795f0c32a1530';
    case 'HIELO ESPECIAL':
      return '5ef5e43bdd4795f0c32a1531';
    case 'CUBO 5X5':
      return '6509a1e01bb0978fd8b5b15f' ;
    case 'LINGOTE 4X12':
      return '6509a4241bb0978fd8b5b161';
    case 'ESFERAS 6CM':
      return '650dbb3eb78327095c20a226' ;
    case 'CUBO 5,5 X 5,5':
      return '650aae4834900b974d3fba94' ;
    case 'CUBO 5X7':
      return '650aae7934900b974d3fba95' ;
    case 'LINGOTE 3X15':
      return '650aae9a34900b974d3fba96';
    case 'ESFERAS 3CM':
      return '650aaebe34900b974d3fba97' ;
}
}

export function ProductInitialsByNAME(name: string) {
  name = name ? name.toUpperCase() : '';
  switch (name) {
    case 'CUBITO VOGT':
      return 'CV';
    case 'CUBITO SCOTSMAN':
      return 'CS';
    case 'HIELO PILEE':
      return 'HP';
    case 'ESCAMA RED':
      return 'ER';
    case 'ESCAMA CUAD':
      return 'EC';
    case 'HIELO CUADRADO':
      return 'HC';
    case 'BARRA':
      return 'BA';
    case 'BLOQUES':
      return 'BL';
    case 'HIELO SECO':
      return 'HS';
    case 'DESPLAZAMIENTO':
      return 'D';
    case 'ALQUILER CAMARAS':
      return 'AC';
    case 'HIELO ESPECIAL':
      return 'HE';
    case'CUBO 5X5' :
      return 'CB5X5';
    case 'LINGOTE 4X12':
      return 'LN4X12' ;
    case 'ESFERAS 6CM':
      return 'ES6';
    case 'CUBO 5,5 X 5,5':
      return 'CB5,5';
    case 'CUBO 5X7':
      return 'CB5X7'; 
    case 'LINGOTE 3X15' :
      return 'LN3X15' ;
    case 'ESFERAS 3CM':
      return 'ES3';
  }
}

export function ProductInitialsByID(name: string) {
  switch (name) {
    case '5eb9c9e3d0ca13a4332680dc':
      return 'CV';
    case '5eb9c9e3d0ca13a4332680db':
      return 'CS';
    case '5eb9c9e3d0ca13a4332680d9':
      return 'HP';
    case '5eb9c9e3d0ca13a4332680da':
      return 'ER';
    case '5ef5e43bdd4795f0c32a152b':
      return 'EC';
    case '5ea75b95ece96f572f598255':
      return 'HC';
    case '5ef5e43bdd4795f0c32a152c':
      return 'BA';
    case '5ef5e43bdd4795f0c32a152d':
      return 'BL';
    case '5ef5e43bdd4795f0c32a152e':
      return 'HS';
    case '5ef5e43bdd4795f0c32a152f':
      return 'D';
    case '5ef5e43bdd4795f0c32a1530':
      return 'AC';
    case '5ef5e43bdd4795f0c32a1531':
      return 'HE';
    case '6509a1e01bb0978fd8b5b15f':
      return 'CB5X5';
    case '6509a4241bb0978fd8b5b161':
      return 'LN4X12';
    case '650dbb3eb78327095c20a226':
      return 'ES6';
    case '650aae4834900b974d3fba94':
      return 'CB5,5' ;
    case '650aae7934900b974d3fba95':
      return 'CB5X7' ;
    case '650aae9a34900b974d3fba96':
      return 'LN3X15' ;
    case'650aaebe34900b974d3fba97' :
      return 'ES3';
  }
}

export const ProductList = [
  'CUBITO VOGT',
  'CUBITO SCOTSMAN',
  'HIELO PILEE',
  'ESCAMA RED',
  'ESCAMA CUAD',
  'HIELO CUADRADO',
  'BARRA',
  'BLOQUES',
  'HIELO SECO',
  'DESPLAZAMIENTO',
  'ALQUILER CAMARAS',
  'HIELO ESPECIAL',
  'CUBO 5X5',
  'LINGOTE 4X12',
  'ESFERAS 6CM',
  'CUBO 5,5 X 5,5',
  'CUBO 5X7',
  'LINGOTE 3X15',
  'ESFERAS 3CM'
]
export const ProductListInitials = [
  'CV',
  'CS',
  'HP',
  'ER',
  'EC',
  'HC',
  'BA',
  'BL',
  'HS',
  'D',
  'AC',
  'HE',
  'CB5X5',
  'LN4X12',
  'ES6',
  'CB5,5',
  'CB5X7',
  'LN3X15',
  'ES3'
]