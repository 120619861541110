import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { RestService } from 'src/app/services/rest/rest.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-mobile-table',
  templateUrl: './mobile-table.component.html',
  styleUrls: ['./mobile-table.component.scss'],
})
export class MobileTableComponent implements OnInit {
  @Input()
  columns: any[];
  @Input()
  lines: any[];
  @Input()
  withInput = false;
  @Input()
  maxPage: number;
  @Input()
  currentPage: any;
  @Input()
  pagination = false;
  @Input()
  showAll = false;
  @Input()
  showInfoIcon = false;
  @Input()
  stockOn = false;
  @Input()
  cargasOn = false;
  @Input()
  albaranOn = false;
  @Input()
  closeSessionOn = false;
  @Input()
  allFacturasOn = false;
  @Input()
  facturasOn = false;
  @Input()
  facturasUnsetOn = false;

  @Output() doActionParent = new EventEmitter();
  @Output() paginacionParent = new EventEmitter();
  @Output() goToFirst = new EventEmitter();
  @Output() goToLast = new EventEmitter();
  @Output() sendLimit = new EventEmitter();

  constructor(
    private alert: AlertController,
    private rest: RestService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    if (this.maxPage) {
      console.log(this.currentPage);
    }
  }

  doAction(action, line) {
    const sendData = { action, line };
    this.doActionParent.emit(sendData);
  }

  trackById(i, line) {
    return line._id;
  }

  checkDisabled(line, validate, validateType = 'string') {
    if (validateType === 'boolean') {
      return line[validate];
    } else {
      if (line[validate].length !== 0) {
        return false;
      } else {
        return true;
      }
    }
  }

  async showInfo(line: any) {
    console.log(line);
    const alert = await this.alert.create({
      cssClass: 'table_alert',
      header: line.nombre ? line.nombre : 'Información',
      subHeader: line.documento ? line.documento : '',
      message: await this.checkMessage(line),
      buttons: ['Cerrar'],
    });
    await alert.present();
  }

  async checkMessage(line: any) {
    let message: string;
    if (this.stockOn) {
      message = `<b>CUBITO VOGT:</b> ${line.prod1 ? line.prod1 : 'Sin datos'}<br>
      <b>CUBITO SCOTSMAN:</b> ${line.prod2 ? line.prod2 : 'Sin datos'}<br>
      <b>HIELO PILEE:</b> ${line.prod3 ? line.prod3 : 'Sin datos'}<br>
      <b>ESCAMA RED:</b> ${line.prod4 ? line.prod4 : 'Sin datos'}<br>
      <b>ESCAMA CUAD:</b> ${line.prod5 ? line.prod5 : 'Sin datos'}<br>
      <b>HIELO CUADRADO:</b> ${line.prod6 ? line.prod6 : 'Sin datos'}<br>
      <b>BARRA:</b> ${line.prod7 ? line.prod7 : 'Sin datos'}<br>
      <b>BLOQUES:</b> ${line.prod8 ? line.prod8 : 'Sin datos'}<br>
      <b>HIELO SECO:</b> ${line.prod9 ? line.prod9 : 'Sin datos'}<br>
      <b>HIELO ESPECIAL:</b> ${line.prod10 ? line.prod10 : 'Sin datos'}<br>
      <b>CUBO 5X5:</b> ${line.prod11 ? line.prod11 : 'Sin datos'}<br>
      <b>LINGOTE 4X12:</b> ${line.prod12 ? line.prod12 : 'Sin datos'}<br>
      <b>ESFERAS 6CM:</b> ${line.prod13 ? line.prod13 : 'Sin datos'}<br>
      <b>CUBO 5,5 X 5,5:</b> ${line.prod14 ? line.prod14 : 'Sin datos'}<br>
      <b>CUBO 5X7:</b> ${line.prod15 ? line.prod15 : 'Sin datos'}<br>
      <b>LINGOTE 3X15:</b> ${line.prod16 ? line.prod16 : 'Sin datos'}<br>
      <b>ESFERAS 3CM:</b> ${line.prod17 ? line.prod17 : 'Sin datos'}<br>`;
    } else if (this.cargasOn) {
      message = `
      <b>Repartidor:</b> ${line.repartidor ? line.repartidor : 'Sin datos'}<br>
      <b>Vehículo:</b> ${line.matricula ? line.matricula : 'Sin datos'}<br>
      <b>Aceptada:</b> ${line.isAccepted ? 'Si' : 'No'}<br>
      <b>Entregue:</b> ${line.closed ? 'Si' : 'No'}<br>
      `;
      line.product.forEach(elem => {
        message = message + `<b>${this.fiterProduct(elem._id)}: </b>${elem.unit}<br>`
      });
    } else if (this.albaranOn) {
      const time = new Date(line.updatedAt).toLocaleTimeString('es-ES').slice(0, -3);
      line.updatedAt = new Date(line.updatedAt).toLocaleDateString('es-ES');
      line.updatedAt = line.updatedAt + ' - ' + time;
      message = `
      <b>Dirección:</b> ${line.dfiscal ? line.dfiscal : 'Sin datos'}<br>
      <b>Cliente:</b> ${line.nombreCliente ? line.nombreCliente : 'Sin datos'}<br>
      <b>Fecha y Hora:</b> ${line.updatedAt ? line.updatedAt : 'Sin datos'}<br>
      <b>Facturado:</b> ${line.facturado ? 'Si' : 'No'}<br>
      <b>Repartidor:</b> ${await this.getRepartidorInfo(line)}<br>
      <b>Eliminado:</b> ${line.deleted ? 'Si' : 'No'}<br>`;
      line.cantidad.forEach(elem => {
        message = message + `<b>${this.fiterProduct(elem.product)}: </b>${elem.unit}<br>`
      });
    } else if (this.closeSessionOn) {
      const unitsReturn = this.calcUnits(line);
      message = `<b>Empleado:</b> ${line.firstName ? line.firstName : 'Sin datos'}<br>
      <b>Fecha de inicio:</b> ${line.start ? line.start : 'Sin datos'}<br>
      <b>Fecha cierre:</b> ${line.end ? line.end : 'Sin datos'}<br>
      <b>Número:</b> ${line.number ? line.number : 'Sin datos'}<br>
      <b>Pago:</b> ${line.isPaid ? 'Si' : 'No'}<br>
      <b>Total recaudado:</b> ${line.total_money}<br>
      <b>Unidades a devolver:</b> ${unitsReturn ? unitsReturn : 0}<br>`;
    } else if (this.allFacturasOn || this.facturasUnsetOn) {
      message = `
      <b>Nombre:</b> ${line.nombre ? line.nombre : 'Sin datos'}<br>
      <b>CIF:</b> ${line.documento ? line.documento : 'Sin datos'}<br>
      <b>Serie:</b> ${line.serie ? line.serie : 'Sin datos'}<br>
      <b>Año:</b> ${line.year ? line.year : 'Sin datos'}<br>
      <b>Fecha:</b> ${line.fecha ? line.fecha : 'Sin datos'}<br>
      <b>Total:</b> ${line.total ? line.total : 'Sin datos'}<br>
      <b>Fecha pago:</b> ${line.pago ? line.pago : 'Sin datos'}<br>
      <b>Comentario:</b> ${line.comment ? line.comment : 'Sin datos'}<br>
      `;
    } else if (this.facturasOn) {
      message = `
      <b>Nombre:</b> ${line.nombre ? line.nombre : 'Sin datos'}<br>
      <b>Email:</b> ${line.email ? line.email : 'Sin datos'}<br>
      <b>Serie:</b> ${line.serie ? line.serie : 'Sin datos'}<br>
      <b>Total:</b> ${line.total ? line.total : 'Sin datos'}<br>
      `;
    } else {
      message = `<b>Dirección:</b> ${line.dfiscal ? line.dfiscal : 'Sin datos'}<br><b>Email:</b> ${line.correo ? line.correo : 'Sin datos'}<br><b>Denominación:</b> ${line.denominacion ? line.denominacion : 'Sin datos'}`;
    }
    return message;
  }

  async getRepartidorInfo(line: any) {
    await this.utils.showLoading();
    const result: any = await this.rest.getUser(line.empleado);
    await this.utils.hideLoading();
    if (result && result.ok) {
      return result.body.firstName;
    } else {
      return 'Sin datos';
    }
  }

  calcUnits(line: any) {
    const userAlbs = line.albaranes;
    let userCargas = line.cargas;

    if (userCargas.length > 0) {
      line.un_return = '';
      userCargas.forEach(
        (carga, index) => {
          line.un_return += (carga.furgoneta.units || '') +
            ((index === userCargas.length - 1 || !carga.furgoneta.units) ? '' : ', ');
        }
      );
      userCargas = userCargas.sort((a, b) => b.updatedAt - a.updatedAt);
      userCargas = userCargas[0];
      let auxAlbsUnits = userAlbs.map(elem => elem.unit);
      auxAlbsUnits = auxAlbsUnits.reduce((a, b) => a + b, 0);
    }
    return line.un_return;
  }

  goTo(data: string) {
    if (data === 'first') {
      this.goToFirst.emit();
    } else {
      this.goToLast.emit();
    }
  }

  prev() {
    this.paginacionParent.emit(-1);
  }

  next() {
    this.paginacionParent.emit(1);
  }

  fiterProduct(data: string) {
    switch (data) {
      case '5eb9c9e3d0ca13a4332680dc': {
        return 'CUBITO VOGT';
        break;
      }
      case '5eb9c9e3d0ca13a4332680db': {
        return 'CUBITO SCOTSMAN';
        break;
      }
      case '5eb9c9e3d0ca13a4332680d9': {
        return 'HIELO PILEE';
        break;
      }
      case '5eb9c9e3d0ca13a4332680da': {
        return 'ESCAMA RED';
        break;
      }
      case '5ef5e43bdd4795f0c32a152b': {
        return 'ESCAMA CUAD';
        break;
      }
      case '5ea75b95ece96f572f598255': {
        return 'HIELO CUADRADO';
        break;
      }

      case '5ef5e43bdd4795f0c32a152c': {
        return 'BARRA';
        break;
      }
      case '5ef5e43bdd4795f0c32a152d': {
        return 'BLOQUES';
        break;
      }
      case '5ef5e43bdd4795f0c32a152e': {
        return 'HIELO SECO';
        break;
      }
      case '5ef5e43bdd4795f0c32a152f': {
        return 'DESPLAZAMIENTO';
        break;
      }
      case '5ef5e43bdd4795f0c32a1530': {
        return 'ALQUILER CAMARAS';
        break;
      }
      case '5ef5e43bdd4795f0c32a1531': {
        return 'HIELO ESPECIAL';
        break;
      }
      case '6509a1e01bb0978fd8b5b15f': {
        return 'CUBO 5X5';
        break;
      }
      case '6509a4241bb0978fd8b5b161': {
        return 'LINGOTE 4X12';
        break;
      }
      case '5650dbb3eb78327095c20a226': {
        return 'ESFERAS 6CM';
        break;
      }
      case '650aae4834900b974d3fba94': {
        return 'CUBO 5,5 X 5,5';
        break;
      }
      case '650aae7934900b974d3fba95': {
        return 'CUBO 5X7';
        break;
      }
      case '650aae9a34900b974d3fba96': {
        return 'LINGOTE 3X15';
        break;
      }
      case '650aaebe34900b974d3fba97': {
        return 'ESFERAS 3CM';
        break;
      }
    }
  }
}
