import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-edit-table',
  templateUrl: './edit-table.component.html',
  styleUrls: ['./edit-table.component.scss'],
})
export class EditTableComponent implements OnInit {
  siguienteId: any;
  newId: any;

  constructor(private modal: ModalController) { }

  ngOnInit() { }

  save() {
    this.newId = parseInt((document.getElementById('newId') as any).value, 10);
    this.modal.dismiss(this.newId);
  }
}
