import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/interfaces/user';
import { ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ProductById, ProductInitialsByNAME } from 'src/app/data/products';

@Component({
  selector: 'app-session-summary',
  templateUrl: './session-summary.component.html',
  styleUrls: ['./session-summary.component.scss'],
})
export class SessionSummaryComponent implements OnInit {

  currentUser: User;
  sessionData: any;
  summary: any;
  keysSummary: any;

  constructor(private modal: ModalController,
    private utils: UtilsService) { }

  async dismiss(data = null) {
    await this.modal.dismiss(data);
  }

  productNameById() {
    console.log(this.summary);
    Object.keys(this.summary).forEach(
      key => {
        console.log(key);
        if (key.length > 2) {
          let productName: any = ProductById(key);
          productName = ProductInitialsByNAME(productName);
          this.summary[productName] = this.summary[key];
          console.log(this.summary[key]);
          console.log(this.summary);
        }
        delete this.summary[key];
      }
    );
    this.keysSummary = Object.keys(this.summary);
  }

  ngOnInit() {
    console.log('AQUIII');
    this.productNameById();
    console.log(this.currentUser);
    console.log(this.sessionData);
    this.parseDecimals(this.sessionData.total_money)
  }
  parseDecimals(num: number) {
    this.sessionData.total_money = this.utils.parseDecimals(num);
  }

}
